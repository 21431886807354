// UpdateInvoiceCoordinatesModal.js
import React, { useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const UpdateInvoiceCoordinatesModal = ({ isOpen, onCancel, onFinish, loading, form, data }) => {
    const { t } = useTranslation();
    // console.log(data);

    // Efecto para cargar los valores cuando se abre el modal
    useEffect(() => {
        if (isOpen && data) {
            form.setFieldsValue({
                latitude: data.latitude || '',
                longitude: data.longitude || '',
            });
        }
    }, [isOpen, data, form]);

    return (
        <Modal
            title={
                <div style={{ textAlign: 'center', width: '100%' }}>
                    {`${t('invoice.update_coordinates')} - ${data.code || ''}`}
                </div>
            }
            open={isOpen}
            onCancel={onCancel}
            footer={null}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="latitude"
                    label={t('invoice.form_latitude')}
                    rules={[
                        { required: true, message: t('invoice.latitude_required') },
                        { 
                            pattern: /^-?\d+(\.\d{1,6})?$/, 
                            message: t('invoice.latitude_invalid') 
                        },
                    ]}
                >
                    <Input placeholder={t('invoice.form_latitude')} />
                </Form.Item>

                <Form.Item
                    name="longitude"
                    label={t('invoice.form_longitude')}
                    rules={[
                        { required: true, message: t('invoice.longitude_required') },
                        { 
                            pattern: /^-?\d+(\.\d{1,6})?$/, 
                            message: t('invoice.longitude_invalid') 
                        },
                    ]}
                >
                    <Input placeholder={t('invoice.form_longitude')} />
                </Form.Item>

                <Form.Item>
                    <div style={{ textAlign: 'center' }}>
                        <Button type="default" danger onClick={onCancel} style={{ marginRight: 8 }}>
                            {t('global.cancel')}
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {t('global.acept')}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UpdateInvoiceCoordinatesModal;
