// import logowelcome from '../views/images/logowelcome.jpg'

// const PageWelcome = ({ ...props }) => {
// 	return (
// 		<div>
// 		  {/*<img src={logowelcome} alt="welcome" width="900" height="400"/>*/}
// 		</div>
//     );
// }
//
// export default PageWelcome;
import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Table } from 'antd';
import { UsergroupAddOutlined, UserOutlined, UserDeleteOutlined, CalendarOutlined } from '@ant-design/icons';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

export const Axios = axios.create({
	baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const PageWelcome = () => {
	const [data, setData] = useState([]);
	const [totals, setTotals] = useState(null); // Para los valores de totales

	const headers = {
		'accept': '*/*',
		'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
		'Content-Type': 'application/json',
	};

	useEffect(() => {
		Axios.get('/mts/dashboard', { headers })
			.then(response => {
				const responseData = response.data.data;
				if (responseData.length > 0) {
					const totalData = {
						total_usuarios: responseData[0].total_usuarios,
						usuarios_activos: responseData[0].usuarios_activos,
						usuarios_inactivos: responseData[0].usuarios_inactivos,
						usuarios_registrados_ultimos_7_dias: responseData[0].usuarios_registrados_ultimos_7_dias
					};
					setTotals(totalData);
					setData(responseData);
				}
			})
			.catch(error => {
				console.error('Error fetching data:', error);
			});
	}, []);

	if (!totals) {
		return true;
		// return <p>Loading...</p>;
	}

	// Definición de columnas para la tabla de usuarios por rol
	const columns = [
		{
			title: 'Rol',
			dataIndex: 'denominacion',
			key: 'denominacion',
		},
		{
			title: 'Cantidad de Usuarios',
			dataIndex: 'cantidad_usuarios_por_rol',
			key: 'cantidad_usuarios_por_rol',
		}
	];

	// Datos para la gráfica de pastel
	const pieData = data.map(item => ({
		name: item.denominacion,
		value: item.cantidad_usuarios_por_rol
	}));

	// Colores para las secciones del pastel
	const COLORS = ['#A9754F', '#495057', '#9B1B30', '#005960'];

	// Estilos en línea para las tarjetas y la gráfica
	const cardStyles = {
		totalUsuarios: {
			backgroundColor: '#D0BFFF', // Azul claro
			textAlign: 'center',
			// padding: '20px',
		},
		usuariosActivos: {
			backgroundColor: '#8ce99a', // Verde claro
			textAlign: 'center',
			// padding: '20px',
		},
		usuariosInactivos: {
			backgroundColor: '#ffa8a8', // Rojo claro
			textAlign: 'center',
			// padding: '20px',
		},
		usuariosRegistrados: {
			backgroundColor: '#ffa94d', // Morado claro
			textAlign: 'center',
			// padding: '20px',
		},
		value: {
			fontSize: '28px', // Tamaño del texto aumentado
			margin: '0',
		},
		graphContainer: {
			marginTop: '0px',
			height: '380px', // Altura fija para la gráfica
			width: '100%', // Asegura que la gráfica ocupe todo el ancho disponible
		},
	};

	return (
		<div style={{ padding: '20px' }}>
			<Row gutter={16}>
				<Col xs={24} sm={12} md={6}>
					<Card
						title={<span><UsergroupAddOutlined /> Total de Usuarios</span>}
						bordered={false}
						style={cardStyles.totalUsuarios}
					>
						<h2 style={cardStyles.value}>{totals.total_usuarios}</h2>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={6}>
					<Card
						title={<span><UserOutlined /> Usuarios Activos</span>}
						bordered={false}
						style={cardStyles.usuariosActivos}
					>
						<h2 style={cardStyles.value}>{totals.usuarios_activos}</h2>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={6}>
					<Card
						title={<span><UserDeleteOutlined /> Usuarios Inactivos</span>}
						bordered={false}
						style={cardStyles.usuariosInactivos}
					>
						<h2 style={cardStyles.value}>{totals.usuarios_inactivos}</h2>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={6}>
					<Card
						title={<span><CalendarOutlined /> Registrados Últimos 7 Días</span>}
						bordered={false}
						style={cardStyles.usuariosRegistrados}
					>
						<h2 style={cardStyles.value}>{totals.usuarios_registrados_ultimos_7_dias}</h2>
					</Card>
				</Col>
			</Row>

			<Row gutter={16} style={{ marginTop: '20px' }}>
				<Col xs={24} md={12}>
					<Card title="Cantidad de Usuarios por Rol" bordered={false}>
						<Table
							dataSource={data}
							columns={columns}
							rowKey="denominacion"
							pagination={false}
						/>
					</Card>
				</Col>
				<Col xs={24} md={12}>
					<Card title="Gráfica de Usuarios por Rol" bordered={false} style={cardStyles.graphContainer}>
						<PieChart width={500} height={300}>
							<Pie
								data={pieData}
								dataKey="value"
								nameKey="name"
								cx="50%"
								cy="50%"
								outerRadius={100}
								fill="#8884d8"
								label
							>
								{pieData.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
								))}
							</Pie>
							<Tooltip />
							<Legend />
						</PieChart>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default PageWelcome;






