import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Dropdown, Menu, Row, Space } from "antd";
import {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { LoginContext } from "../components/seguridad/servicios/LoginServicios";
import { FormattedMessage } from "react-intl";

const UsuarioDropdown = () => {
  const navigate = useNavigate();
  const { logout } = useContext(LoginContext);

  // Obtención de información del usuario
  const inforUser = sessionStorage.getItem("info_user")
    ? JSON.parse(sessionStorage.getItem("info_user"))
    : null;

  // Verifica si la sesión está activa, de lo contrario, cierra sesión
  if (!inforUser) {
    logout();
  }

  // Función para redirigir al cambio de contraseña
  const handlePasswordChange = () => {
    navigate(`/changemypass/${inforUser?.idusuario}`);
  };

  // Elementos del menú del usuario
  const menuItems = [
    {
      key: "1",
      label: (
        <FormattedMessage
          id="menu_cambiar_password"
          defaultMessage="Cambiar mi contraseña"
        />
      ),
      icon: <LockOutlined />,
      onClick: handlePasswordChange,
    },
    {
      key: "2",
      label: (
        <FormattedMessage
          id="menu_cerrar_session"
          defaultMessage="Cerrar Sesión"
        />
      ),
      icon: <LogoutOutlined />,
      onClick: logout,
    },
  ];

  return (
    <Dropdown
      overlay={
        <Menu
          className="menu-sider-layout menu-perfil"
          theme="dark"
          items={menuItems}
        />
      }
      overlayStyle={{ paddingTop: 4 }}
    >
      <Row align="middle" className="usuario-menu">
        <Space style={{ height: 42, margin: "0 20px" }}>
          <Avatar
            size="large"
            icon={<UserOutlined />}
            style={{
              margin: "-5px 0 0 0",
              backgroundColor: "var(--tema-color-base-claro)",
              borderRadius: 10,
            }}
          />
          <h4 style={{ color: "white" }} className="trigger_option">
            {inforUser?.correo || ""}
          </h4>
          <DownOutlined className="trigger_option" />
        </Space>
      </Row>
    </Dropdown>
  );
};

export default UsuarioDropdown;
