import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Select, Spin, Space, Row, Col, Tooltip} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {MdAddHome} from "react-icons/md";
import {EyeOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {
    AiFillAlert,
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {IoReload  } from "react-icons/io5";
import Search from "antd/es/input/Search";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import MapModal from '../mapa/MapModal';


export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});
const { Option } = Select;


const Rutas = () => {

    if(sessionStorage.getItem('user_mod'))
       sessionStorage.removeItem('user_mod');

    const { t, i18n } = useTranslation();
    const [bottom, setBottom] = useState('bottomCenter');
    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState(t('global.loading'));
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading] = useState(false);
    const [searchValue, setSearchValue] = useState(''); // Estado para controlar el valor del input
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRuta, setSelectedRuta] = useState('');
    const [dataRuta, setDataRuta] = useState('');

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    useEffect(() => {
        recargar();
    }, []);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('ruta.col_matricula')}</p>,
            dataIndex: 'matricula',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.matricula), _.isEqual),
            onFilter: (value, record) => record.matricula.startsWith(value),
            sorter: (a, b) => a.matricula - b.matricula,
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('ruta.col_tipo_vehiculo')}</p>,
            dataIndex: 'tipo_vehiculo',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.tipo_vehiculo), _.isEqual),
            onFilter: (value, record) => record.tipo_vehiculo.startsWith(value),
            sorter: (a, b) => a.tipo_vehiculo - b.tipo_vehiculo
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('ruta.col_chofer')}</p>,
            dataIndex: 'fullname',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.fullname), _.isEqual),
            onFilter: (value, record) => record.fullname.startsWith(value),
            sorter: (a, b) => a.fullname - b.fullname
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('ruta.col_cantidadpaquetes')}</p>,
            dataIndex: 'cantidadpaquetes',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cantidadpaquetes), _.isEqual),
            onFilter: (value, record) => record.cantidadpaquetes.startsWith(value),
            sorter: (a, b) => a.cantidadpaquetes - b.cantidadpaquetes
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('ruta.col_distanciatotal')}</p>,
            dataIndex: 'distanciatotal',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.distanciatotal), _.isEqual),
            onFilter: (value, record) => record.distanciatotal.startsWith(value),
            sorter: (a, b) => a.distanciatotal - b.distanciatotal
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('ruta.col_tiempoestimado')}</p>,
            dataIndex: 'tiempoestimado',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.tiempoestimado), _.isEqual),
            onFilter: (value, record) => record.tiempoestimado.startsWith(value),
            sorter: (a, b) => a.tiempoestimado - b.tiempoestimado
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('ruta.col_estadoruta')}</p>,
            dataIndex: 'estadoruta',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.estadoruta), _.isEqual),
            onFilter: (value, record) => record.estadoruta.startsWith(value),
            sorter: (a, b) => a.estadoruta - b.estadoruta,
            render: (text, record) => {
                let isFullCapacity = false;
                if(record.idestadoruta === 2){
                    isFullCapacity = true;
                }
                return (
                    <span style={{ color: isFullCapacity ? "red" : "green" }}>
                        {text}
                    </span>
                );
            },
        }/*,{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('ruta.col_ruta')}</p>,
            dataIndex: 'ruta',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.ruta), _.isEqual),
            onFilter: (value, record) => record.ruta.startsWith(value),
        }*/
    ];

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: data.length, // Asegúrate de tener el total de elementos aquí
    });

    const handleTableChange = (newPagination) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    const recargar = () => {
        //limpiar la busqueda del componente Search
        setSearchValue('');

        setSpinTitle(t('global.loading'));
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        let start = 0;
        let limit = 1000;

        Axios.post("sd/getRutas",
            {
                // "start": start,
                // "limit": limit,
                // "filtro":[{
                //     "idruta": 1,
                //     "idvehiculo": 1,
                // }]
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        key: response.data.data[i].idruta,
                        idruta: response.data.data[i].idruta,
                        idvehiculo: response.data.data[i].idvehiculo,
                        ruta: response.data.data[i].ruta,
                        fechacreacion: response.data.data[i].fechacreacion,
                        fechaactualizacion: response.data.data[i].fechaactualizacion,
                        cantidadpaquetes: response.data.data[i].cantidadpaquetes,
                        distanciatotal: response.data.data[i].distanciatotal,
                        tiempoestimado: response.data.data[i].tiempoestimado,
                        matricula: response.data.data[i].matricula,
                        modelo: response.data.data[i].modelo,
                        tipo_vehiculo: response.data.data[i].tipo_vehiculo,
                        fullname: response.data.data[i].fullname,
                        telefono: response.data.data[i].telefono,
                        idestadoruta: response.data.data[i].idestadoruta,
                        estadoruta: response.data.data[i].estadoruta
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        }).catch(error => {
            setSpinning(false);
        })
    };

    const getInfoForm = async (id) => {
        for(let i=0; i<data.length; i++){
            if(data[i].idruta === id){
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('user_mod', JSON.stringify(row));
    };

    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.matricula.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelectedRowKeys.length === 1) {
            const selectedRow = data.find(row => row.key === newSelectedRowKeys[0]);
            if (selectedRow) {
                setSelectedRuta(JSON.parse(selectedRow.ruta));
                setDataRuta(selectedRow);
            }
        }
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate  = async (record) => {
        setSelectedRowKeys([record.key]);
        form.resetFields();
        let row = [];
        for(let i=0;i<data.length;i++){
            if(data[i].idruta === record.key){
                row = data[i];
            }
        }
       
        sessionStorage.setItem('user_mod', JSON.stringify(row));
        navigate(`/editTipoVehiculo/${record.key}`)
        
    };

    function cancelarRuta(e, cancelar) {

        Modal.confirm({
            title: 'Cancelar ruta',
            cancelText: 'Cancelar',
            okText: 'Aceptar',
            okType: 'primary',
            content: (
                <div>
                    <span>¿Desea cancelar la ruta seleccionada?</span>
                </div>
            ),
            onOk() {
                setSpinTitle(t('global.processing_data'));
                setSpinning(true);
                let message = '';
                Axios.post("sd/cancelarRuta",
                    {
                        idruta: selectedRowKeys[0],
                        idestadoruta: (cancelar) ? 2 : 1,
                        data: dataRuta,
                    },
                    {
                        headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if (response.data.success) {
                        message = response.data.message;
                        messageApi.open({
                            type: 'success',
                            content: message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    } else {
                        messageApi.open({
                            type: 'error',
                            content: message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: message,
                            duration: 0,
                        });
                        setTimeout(messageApi.destroy, 2500);
                    });
            },
        });
    }

    function deleteRuta(e, cancelar) {

        Modal.confirm({
            title: 'Eliminar ruta',
            cancelText: 'Cancelar',
            okText: 'Aceptar',
            okType: 'primary',
            content: (
                <div>
                    <span>¿Desea eliminar la ruta seleccionada?</span>
                </div>
            ),
            onOk() {
                setSpinTitle(t('global.processing_data'));
                setSpinning(true);
                let message = '';
                Axios.post("sd/deleteRuta",
                    {
                        idruta: [selectedRowKeys[0]],
                        idvehiculo: dataRuta.idvehiculo,
                    },
                    {
                        headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if (response.data.success) {
                        message = response.data.message;
                        messageApi.open({
                            type: 'success',
                            content: message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    } else {
                        messageApi.open({
                            type: 'error',
                            content: message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: message,
                            duration: 0,
                        });
                        setTimeout(messageApi.destroy, 2500);
                    });
            },
        });
    }


    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                //alignItems: 'center',
                padding: '10px'
            }}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    {t('ruta.title')}
                </p>
                {contextHolder}
                <div
                    style={{
                        marginBottom: 10,
                    }}
                >
                    {contextHolder}
                    <Space direction='horizontal'>
                        <Tooltip title={t('ruta.details')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<EyeOutlined size={15}/>} onClick={() => setIsModalOpen(true)} disabled={loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>

                        <Tooltip title={t('ruta.cancelar')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" danger shape="circle"
                                    className="button" icon={<CloseCircleOutlined size={15}/>} onClick={(e) => {
                                cancelarRuta(e, true);
                            }} disabled={loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>

                        <Tooltip title={t('ruta.delete')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" danger shape="circle"
                                    className="button" icon={<AiFillDelete size={15}/>} onClick={(e) => {
                                deleteRuta(e, true);
                            }} disabled={loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>

                        <Tooltip title={t('global.loading')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<IoReload/>} onClick={recargar}
                                    loading={loading || loading}>

                            </Button>
                        </Tooltip>

                        <Search
                            placeholder={t('ruta.search')}
                            enterButton
                            style={{
                                width: 230,
                            }}
                            size="middle"
                            onSearch={onSearch}
                            value={searchValue} // El valor del input es controlado por el estado
                            onChange={(e) => {
                                setSearchValue(e.target.value); // Actualiza el estado con lo que se escribe
                                filterLocal(e.target.value);
                            }}
                        />
                    </Space>

                    <MapModal
                        isVisible={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        coordinates={selectedRuta}
                    />

                </div>

                <Row>
                    <Col span={24}>
                        <Table size="small"
                            // scroll={{
                            //     y: 350,
                            // }}
                               bordered={true}
                               pagination={{
                                   position: [bottom],
                                   current: pagination.current,
                                   pageSize: pagination.pageSize,
                                   showSizeChanger: true,
                                   pageSizeOptions: ['10', '20', '50', '100'],
                                   showTotal: (total) => `Total ${total} elementos`, // Texto que muestra el total de elementos
                               }}
                               rowSelection={rowSelection}
                               onChange={handleTableChange}
                               columns={columns} dataSource={data}
                               onRow={(record) => {
                                   return {
                                       onDoubleClick: async () => {
                                           // selectRowUpdate(record);
                                       }
                                   }
                               }}/>
                    </Col>
                </Row>


            </div>
        </Spin>
    );
};
export default Rutas;