import {Form, Input, Space, Button, message, Spin, Row, Col} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import {CloseOutlined, SaveOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import { parsePhoneNumberFromString , isValidPhoneNumber  } from 'libphonenumber-js';

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormAddPersona = ({accion}) => {
    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    const [messageApi, contextHolder] = message.useMessage();
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Guardando información ...");
    const [title] = useState((accion === "mod") ? "Modificar Persona" : "Adicionar Persona");
    const navigate = useNavigate();
    const {id} = useParams();
    const [form] = Form.useForm();
    const {t, i18n} = useTranslation();

    const validateEmail = (rule, value, callback) => {
        if (value) {
            const emailRegex = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
            if (accion !== "pass" && !emailRegex.test(value)) {
                callback('Correo Inválido / Invalid email address');
            } else {
                callback();
            }
        }else{
            return Promise.resolve();
        }
    };

    const validatePhoneNumber = (rule, value) => {
        if (!value) {
            return Promise.reject('El número de teléfono es obligatorio');
        }

        // Usa parsePhoneNumberFromString para validar con distintos formatos
        const phoneNumberUS = parsePhoneNumberFromString(value, 'US');
        const phoneNumberCuba = parsePhoneNumberFromString(value, 'CU');

        // Comprueba si el número es válido para EE. UU. o Cuba
        if ((phoneNumberUS && phoneNumberUS.isValid()) || (phoneNumberCuba && phoneNumberCuba.isValid())) {
            return Promise.resolve();
        }

        return Promise.reject('Número de teléfono no válido para EE. UU. o Cuba');
    };

    useEffect(() => {
        if (accion === "mod") {
            if (!sessionStorage.getItem('user_mod')) {
                navigate('/persona');
            } else {
                let user_mod = JSON.parse(sessionStorage.getItem('user_mod'));
                form.setFieldsValue({
                    "nombre": user_mod.nombre,
                    "papell": user_mod.papell,
                    "sapell": user_mod.sapell,
                    "correo": user_mod.correo,
                    "carnet": user_mod.carnet,
                    "telefono": user_mod.telefono,
                    "direccion": user_mod.direccion
                });
            }
        }
    }, []);

    const cancelar = () => {
        navigate('/persona');
    };

    const onFinish = (values) => {
        values.idpersona = id;
        setSpinning(true);

        let message;

        if (accion === "add") {
            setSpinTitle('Guardando datos...');
            Axios.post("sd/addPersona", values, {headers})
                .then(response => {
                    setSpinning(false);
                    message = response.data.message;
                    if (response.data.success) {
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                        });
                        setTimeout(messageApi.destroy, 3500);
                        navigate('/persona');
                    } else {
                        messageApi.open({type: 'error', content: message});
                        setTimeout(messageApi.destroy, 3500);
                    }
                })
                .catch(() => {
                    setSpinning(false);
                    messageApi.open({type: 'error', content: message});
                    setTimeout(messageApi.destroy, 3500);
                });
        } else if (accion === "mod") {
            setSpinTitle('Guardando datos...');
            Axios.post("sd/updatePersona", values, {headers})
                .then(response => {
                    setSpinning(false);
                    if (response.data.success) {
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                        });
                        setTimeout(messageApi.destroy, 2500);
                        navigate('/persona');
                    } else {
                        messageApi.open({type: 'error', content: 'Error al guardar los datos'});
                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                .catch(() => {
                    setSpinning(false);
                    messageApi.open({type: 'error', content: 'Error al guardar los datos'});
                    setTimeout(messageApi.destroy, 2500);
                });
        }
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '10px'
            }}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    {title}
                </p>
                {contextHolder}
                <Row justify="center" style={{width: "100%"}}>
                    <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                        <Form
                            form={form}
                            autoComplete="on"
                            onFinish={onFinish}
                            layout="vertical"
                            initialValues={{activo: 1}}
                        >
                            {/* Fila con 3 columnas */}
                            <Row gutter={16}>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="nombre"
                                        label="Nombre"
                                        rules={[{required: true, message: 'Por favor debe poner el nombre!'}]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="papell"
                                        label="Primer apellido"
                                        rules={[{required: true, message: 'Por favor debe poner el primer apellido!'}]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="sapell"
                                        label="Segundo apellido"
                                        rules={[{required: true, message: 'Por favor debe poner el segundo apellido!'}]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Segunda fila con 3 columnas */}
                            <Row gutter={16}>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="carnet"
                                        label="CI / Pasaporte"
                                        rules={[
                                            {required: true, message: 'Por favor debe poner CI o Pasaporte!'},
                                            {min: 8, message: 'Debe introducir al menos 8 caracteres'},
                                            {max: 11, message: 'No debe introducir más de 11 caracteres'}
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="correo"
                                        label="Correo electrónico"
                                        rules={[{ required: false, message: 'Por favor debe poner el correo!' },{ validator: validateEmail }]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="telefono"
                                        label="Teléfono"
                                        rules={[
                                            // {required: true, message: 'Por favor debe poner el teléfono!'},
                                            {validator: validatePhoneNumber,}
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Tercera fila con una columna completa */}
                            <Row gutter={16}>
                                <Col xs={24}>
                                    <Form.Item
                                        name="direccion"
                                        label="Dirección"
                                        rules={[{required: true, message: 'Por favor debe poner la dirección!'}]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Botones de acción */}
                            <Form.Item style={{textAlign: "center"}}>
                                <Space>
                                    <Button icon={<CloseOutlined/>} danger onClick={cancelar}>
                                        {t('global.cancel')}
                                    </Button>
                                    <Button icon={<SaveOutlined/>} type="primary" htmlType="submit">
                                        {t('global.save')}
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Spin>
    );
};

export default FormAddPersona;
