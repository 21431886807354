import React, { useState } from 'react';
import axios from 'axios';
import {Table, Input, Button, Form, DatePicker, Modal, Space, Tooltip, Spin} from 'antd';
import moment from 'moment';
import {FormattedMessage} from "react-intl";
// import {SearchOutlined} from "react-icons/io5";
import {SearchOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

function Invoices() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando datos...");
    const [bottom, setBottom] = useState('bottomCenter');

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    const fetchShippingData = async (values) => {

        const { enterprise, dates, status } = values;

        let inidate = null;
        let enddate = null;

        if (dates && dates.length === 2) {
            // Verifica que dates tenga exactamente dos fechas
            const [startDate, finishDate] = dates;
            if (startDate && finishDate) {
                inidate = startDate.format('YYYY-MM-DD');
                enddate = finishDate.format('YYYY-MM-DD');
            }
        }

        // console.log('Inidate:', inidate); // Para depuración
        // console.log('Enddate:', enddate); // Para depuración

        const url = 'https://www.solved1.com/api/appi/playa-dev/shippingdata/';
        const params = {
            apikey: '6I77Z9V01XV7IE3U5BM7XQ4X3D3PNK',
            data: {
                enterprise: enterprise,
                inidate: inidate,
                enddate: enddate,
                status: status || '',
            },
        };

        setError(null);

        try {
            setSpinTitle("Cargando datos...");
            setSpinning(true);
            const response = await axios.get(url, { params: { apikey: params.apikey, data: JSON.stringify(params.data) } });
            const rawData = response.data;

            if (!rawData || typeof rawData !== 'object') {
                throw new Error('Datos inválidos recibidos');
            }


            const formattedData = Object.keys(rawData).map(code => ({
                code,
                ...rawData[code],
            }));
            // console.log(formattedData);

            // const invoiceData = [];
            //
            // // Recorrer el resultado de la API
            // formattedData.forEach(invoice => {
            //     const formattedInvoice = {
            //         code: invoice.code,
            //         rdatereserve: invoice.rdatereserve,
            //         cname: invoice.cname,
            //         cidentity: invoice.cidentity,
            //         address: invoice.address,
            //         cprovince: invoice.cprovince,
            //         cmunicipality: invoice.cmunicipality,
            //         sname: invoice.sname,
            //         products: invoice.products // Asumiendo que los productos ya tienen el formato correcto
            //     };
            //
            //     // Agregar la factura formateada al array invoiceData
            //     invoiceData.push(formattedInvoice);
            // });
            //
            // // console.log({invoiceData});
            //
            // Axios.post(
            //     '/sd/addInvoiceProducts', // Cambia esto por la URL completa si es necesario
            //     invoiceData,
            //     {
            //         headers: headers
            //     }
            // );




            setData(formattedData);
        } catch (error) {
            setError('Error fetching data');
        } finally {
            //setLoading(false);
            setSpinning(false);
        }
    };

    const columns = [
        {
            title: 'Identificador',
            // title: <p style={{
            //     margin: "0px 0",
            //     fontSize: "15px",
            //     fontWeight: "bold",
            //     color: "#2c3e50",
            //     fontFamily: "'Roboto', sans-serif",
            //     textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            // }}>Identificador</p>,
            dataIndex: 'code',
            key: 'code',
            width: 150,
        },{
            title: 'Fecha de Reserva',
            dataIndex: 'rdatereserve',
            key: 'rdatereserve',
            width: 150,
        },
        {
            title: 'Destinatario',
            dataIndex: 'cname',
            key: 'cname',
            width: 300,
        },
        {
            title: 'Identidad',
            dataIndex: 'cidentity',
            key: 'cidentity',
        },
        {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Provincia',
            dataIndex: 'cprovince',
            key: 'cprovince',
        },
        {
            title: 'Municipio',
            dataIndex: 'cmunicipality',
            key: 'cmunicipality',
        },
        {
            title: 'Remitente',
            dataIndex: 'sname',
            key: 'sname',
        },
    ];

    const productColumns = [
        {
            title: 'Nombre del Producto',
            dataIndex: 'namegood',
            key: 'namegood',
        },
        {
            title: 'Cantidad',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Peso',
            dataIndex: 'weight',
            key: 'weight',
        },{
            title: 'Longitud (cm)',
            dataIndex: 'length',
            key: 'length',
        },{
            title: 'Ancho (cm)',
            dataIndex: 'width',
            key: 'width',
        },{
            title: 'Alto (cm)',
            dataIndex: 'height',
            key: 'height',
        },
        {
            title: 'Dimensiones (LxWxH)',
            render: (text, record) => `${record.length} x ${record.width} x ${record.height} (cm)`,
            key: 'dimensions',
        },
    ];

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: data.length, // Asegúrate de tener el total de elementos aquí
    });

    const handleTableChange = (newPagination) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    const expandedRowRender = (record) => {
        return <Table columns={productColumns} dataSource={record.products} pagination={false} rowKey="namegood" />;
    };

    const onFinish = (values) => {
        fetchShippingData(values);
        setIsModalOpen(false); // Cerrar el modal después de buscar
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false); // Cerrar el modal si se cancela
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>


            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                //alignItems: 'center',
                padding: '10px'
            }}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    Facturas
                </p>

                <Space direction='horizontal'>
                    <Tooltip
                        title={<FormattedMessage id="modulo_user_btn_recargar" defaultMessage="Buscar Envíos"/>}>
                        <Button style={{marginRight: "0px", marginLeft: "10px"}} type="primary" shape="circle"
                                className="button" icon={<SearchOutlined/>} onClick={showModal}>

                        </Button>
                    </Tooltip>
                </Space>

                <Modal
                    title="Formulario de Búsqueda"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Form form={form} onFinish={onFinish} layout="vertical">
                        <Form.Item
                            name="enterprise"
                            label="Empresa"
                            initialValue={"etcexpress"}
                            rules={[{required: true, message: 'Por favor, ingrese la empresa'}]}
                        >
                            <Input placeholder="Empresa"/>
                        </Form.Item>
                        <Form.Item
                            name="dates"
                            label="Rango de Fechas"
                            rules={[{required: true, message: 'Por favor, seleccione el rango de fechas'}]}
                        >
                            <RangePicker format="YYYY-MM-DD"/>
                        </Form.Item>
                        <Form.Item
                            name="status"
                            label="Estado"
                        >
                            <Input placeholder="Estado (opcional)"/>
                        </Form.Item>
                        <Form.Item>
                            <div style={{textAlign: 'center'}}>
                                <Button type="default" danger onClick={handleCancel} style={{marginRight: 8}}>
                                    Cancelar
                                </Button>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    Buscar
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Modal>

                {error && <div>Error: {error}</div>}
                <Table
                    dataSource={data}
                    columns={columns}
                    rowKey="key"
                    expandable={{expandedRowRender}}
                    pagination={{
                        position: [bottom],
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal: (total) => `Total ${total} elementos`, // Texto que muestra el total de elementos
                    }}
                />
            </div>
        </Spin>
    );
}

export default Invoices;
