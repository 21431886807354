import {Checkbox, Form, Input, Modal, Radio, Select, Space, Button, Row, Col, message, Spin} from "antd";
import React, {useContext, useEffect, useState, contextHolder}  from "react";
import {Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import GlobalSelect from "../../generales/GlobalSelect";
import {FormattedMessage} from "react-intl";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormAddTipoVehiculo = ({accion}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando información ...");

    const [title, setTitle] = useState((accion=="mod")?"Modificar Característica":"Adicionar Tipo Vehículo");

    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm();

    const {t, i18n} = useTranslation();

    useEffect(() => {
        if(accion == "mod"){
            if(!sessionStorage.getItem('user_mod')){
                navigate('/tipovehiculo');
            }else{
                let user_mod = JSON.parse(sessionStorage.getItem('user_mod'));

                // if(id !== user_mod.idcaracteristicaomnibus)
                //    navigate('/caracttransporte')

                form.setFieldsValue({
                    "denominacion": user_mod.denominacion,
                    "activo": (user_mod.activo === "1") ? 1 : 0
                    });
            }
               
        }

    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
      };


    const cancelar = (values) => {
        navigate('/tipovehiculo');
    }

    const onFinish = (values) => {
        // console.log('Success:', values);
        form.setFieldsValue({
            "denominacion": values.denominacion
            });
        setSpinning(true);
        if(accion === "add"){
            setSpinTitle('Guardando datos...');
            Axios.post("sd/addTipoVehiculo",
                    {
                      "denominacion": values.denominacion,
                      "activo": values.activo
                    },
                    {
                        headers: headers
                      }
                ).then(response => {
                  setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        navigate('/tipovehiculo');
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar los datos',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        }else if(accion === "mod"){
            setSpinTitle('Modificando datos...');
            Axios.post("sd/updateTipoVehiculo",
            {
              "idtipovehiculo": id,
              "denominacion": values.denominacion,
              "activo": values.activo,
            },
            {
                headers: headers
              }
        ).then(response => {
          setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

                navigate('/tipovehiculo');
            }else{
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
        .catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                content: 'Error al guardar los datos',
                duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);
        });
        }

      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    {title}
                </p>
                {contextHolder}
                <Form
                    form={form}
                    autoComplete="on"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    labelCol={{
                        span: 24, // Coloca la etiqueta en toda la fila
                    }}
                    wrapperCol={{
                        span: 24, // Coloca el input en toda la fila
                    }}
                    name="form_in_modal"
                    initialValues={{
                        activo: 1,
                    }}
                >
                    <Form.Item
                        name="denominacion"
                        label="Denominación"
                        labelAlign="left"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        name="activo"
                    >
                        <Radio.Group style={{display: "flex", justifyContent: "center"}}>
                            <Radio value={1}>
                                <FormattedMessage id="modulo_user_btn_activar" defaultMessage="Activo"/>
                            </Radio>
                            <Radio value={0}>
                                <FormattedMessage id="modulo_user_btn_desactivar" defaultMessage="Inactivo"/>
                            </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item style={{textAlign: "center", marginTop: "16px"}}>
                        <Space>
                            <Button icon={<CloseOutlined/>} danger onClick={cancelar}>
                                {t('global.cancel')}
                            </Button>
                            <Button icon={<SaveOutlined/>} type="primary" htmlType="submit">
                                {t('global.save')}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </Spin>


    );
};

export default FormAddTipoVehiculo;