import {Form, Input, Radio, Space, Button, message, Spin, Col, Row} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import GlobalSelect from "../../generales/GlobalSelect";
import {FormattedMessage} from 'react-intl';
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import { parsePhoneNumberFromString , isValidPhoneNumber  } from 'libphonenumber-js';

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormUsuario = ({accion}) => {

    const {t, i18n} = useTranslation();

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    const validateEmail = (rule, value, callback) => {
        const emailRegex = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
        if (accion !== "pass" && !emailRegex.test(value)) {
            callback('Invalid email address');
        } else {
            callback();
        }
    };

    const validatePhoneNumber = (rule, value) => {
        if (!value) {
            return Promise.reject('El número de teléfono es obligatorio');
        }

        // Usa parsePhoneNumberFromString para validar con distintos formatos
        const phoneNumberUS = parsePhoneNumberFromString(value, 'US');
        const phoneNumberCuba = parsePhoneNumberFromString(value, 'CU');

        // Comprueba si el número es válido para EE. UU. o Cuba
        if ((phoneNumberUS && phoneNumberUS.isValid()) || (phoneNumberCuba && phoneNumberCuba.isValid())) {
            return Promise.resolve();
        }

        return Promise.reject('Número de teléfono no válido para EE. UU. o Cuba');
    };

    const validatePhone = (rule, value, callback) => {
        const phoneRegex = /^(0|[1-9]\d*)$/;
        if (accion !== "pass" && !phoneRegex.test(value)) {
            callback('El teléfono solo debe contener números');
        } else {
            callback();
        }
    };

    const [messageApi, contextHolder] = message.useMessage();

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState(t('global.processing_data'));

    const [title, setTitle] = useState((accion === "mod") ?
        t('usser.title_edit') : t('usser.title_add')
    );

    const [roles, setRoles] = useState([]);

    const navigate = useNavigate();

    const {id} = useParams();

    const [form] = Form.useForm();

    useEffect(() => {
        cargarRoles();
        if (accion === "add") {
            form.resetFields();
        }

        if (accion === "mod") {
            if (!sessionStorage.getItem('user_mod')) {
                navigate('/usuarios');
            } else {
                let user_mod = JSON.parse(sessionStorage.getItem('user_mod'));

                if (id !== user_mod.idusuario)
                    navigate('/usuarios')

                form.setFieldsValue({
                    "nombreusuario": user_mod.nombreusuario,
                    "nombre": user_mod.nombre,
                    "papell": user_mod.papell,
                    "sapell": user_mod.sapell,
                    "carnet": user_mod.carnet,
                    "direccion": user_mod.direccion,
                    "correo": user_mod.correo,
                    "rol": user_mod.idrol,
                    "telefono": user_mod.telefono,
                    "activo": (user_mod.activo === "1") ? 1 : 0
                });
            }

        }

        if (accion === "pass") {
            if (!sessionStorage.getItem('user_mod')) {
                navigate('/usuarios')
            } else {
                let user_mod = JSON.parse(sessionStorage.getItem('user_mod'));

                if (id !== user_mod.idusuario)
                    navigate('/usuarios')

                if (sessionStorage.getItem('user_mod'))
                    setTitle("Cambiar contraseña de " + user_mod.correo);
            }

        }
    }, []);

    const tailLayout = {
        wrapperCol: {marginTop: 10, offset: 8, span: 16},
    };

    const cargarRoles = () => {
        setSpinTitle(t('global.loading'))
        setSpinning(true);
        let aux = [];
        Axios.get("mts/getRoles", {
            headers: headers
        })
            .then(response => {
                setSpinning(false);
                // console.log(response.data.data);
                if (response.data.data.length > 0) {
                    for (var i = 0; i < response.data.data.length; i++) {
                        aux.push({
                            id: response.data.data[i].idrol,
                            nombre: response.data.data[i].denominacion,
                        });
                    }
                }
                setRoles(aux);
            })
            .catch(error => {
                setSpinning(false);
                setRoles(aux);
                // console.log("Error", error);
            })
    };

    const cancelar = (values) => {
        navigate('/usuarios');
    };

    const onFinish = (values) => {
        // console.log("Valores enviados:", values);
        // form.resetFields();
        setSpinning(true);
        if (accion === "add") {
            setSpinTitle(t('global.processing_data'));
            Axios.post("mts/addUsser",
                {
                    "nombreusuario": values.nombreusuario,
                    "nombre": values.nombre,
                    "papell": values.papell,
                    "sapell": values.sapell,
                    "carnet": values.carnet,
                    "direccion": values.direccion,
                    "correo": values.correo,
                    "contrasenna": values.contrasenna,
                    "idrol": values.rol,
                    "telefono": values.telefono,
                    "activo": values.activo
                },
                {
                    headers: headers
                }
            ).then(response => {
                setSpinning(false);
                if (response.data.success) {
                    messageApi.open({
                        type: 'success',
                        content: response.data.message,
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);
                    navigate('/usuarios');
                } else {
                    messageApi.open({
                        type: 'error',
                        content: response.data.message,
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);
                }
            }).catch(error => {
                setSpinning(false);
                messageApi.open({
                    type: 'error',
                    content: 'Error al guardar el usuario',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);


            });
        } else if (accion === "pass") {
            setSpinTitle(t('global.processing_data'));
            Axios.post("mts/changePasswordUsser",
                {
                    "idusuario": id,
                    "contrasenna": values.contrasenna,
                },
                {
                    headers: headers
                }
            ).then(response => {
                setSpinning(false);
                if (response.data.success) {
                    messageApi.open({
                        type: 'success',
                        content: response.data.message,
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);
                    navigate('/usuarios');
                } else {
                    messageApi.open({
                        type: 'error',
                        content: response.data.message,
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);
                }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar el usuario',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        } else if (accion === "mod") {
            setSpinTitle(t('global.processing_data'));
            Axios.post("mts/updateUsser",
                {
                    "idusuario": id,
                    "nombreusuario": values.nombreusuario,
                    "nombre": values.nombre,
                    "papell": values.papell,
                    "sapell": values.sapell,
                    "carnet": values.carnet,
                    "direccion": values.direccion,
                    "correo": values.correo,
                    "idrol": values.rol,
                    "telefono": values.telefono,
                    "activo": values.activo
                },
                {
                    headers: headers
                }
            ).then(response => {
                setSpinning(false);
                if (response.data.success) {
                    messageApi.open({
                        type: 'success',
                        content: response.data.message,
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);

                    navigate('/usuarios');
                } else {
                    messageApi.open({
                        type: 'error',
                        content: response.data.message,
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);
                }
            }).catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar el usuario',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        }

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                minHeight: "100vh",
            }}>
                <div style={{ width: "100%", maxWidth: "1200px", padding: "20px" }}>
                    <p style={{
                        textAlign: "center",
                        margin: "10px 0",
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#2c3e50",
                        fontFamily: "'Roboto', sans-serif",
                        borderBottom: "2px solid #61768B",
                        paddingBottom: "5px",
                        textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                    }}>
                        {title}
                    </p>
                    {contextHolder}
                    <Form
                        form={form}
                        autoComplete="off"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        name="form_in_modal"
                        layout="vertical"
                        initialValues={{
                            nombre: '',
                            papell: '',
                            sapell: '',
                            contrasenna: '',
                            correo: '',
                            nombreusuario: '',
                            carnet: '',
                            confirm: '',
                            telefono: '',
                            direccion: '',
                            activo: 1
                        }}
                    >
                        <Row gutter={[16, 16]}>
                            {/* Primera Columna */}
                            <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                    hidden={accion === "pass"}
                                    name="nombre"
                                    label="Nombre"
                                    rules={[{ required: true, message: 'Por favor debe poner el nombre!' }]}
                                >
                                    <Input tabIndex={1}/>
                                </Form.Item>

                                <Form.Item
                                    hidden={accion === "pass"}
                                    name="carnet"
                                    label="CI / Pasaporte"
                                    rules={[
                                        { required: true, message: 'Por favor debe poner CI o Pasaporte!' },
                                        { min: 8, message: 'Debe introducir al menos 8 caracteres' },
                                        { max: 11, message: 'No debe introducir más de 11 caracteres' }
                                    ]}
                                >
                                    <Input tabIndex={4}/>
                                </Form.Item>
                                <Form.Item
                                    hidden={accion === "pass"}
                                    name="nombreusuario"
                                    label={<FormattedMessage id="tabla_usuario_columna_usuario" defaultMessage='Usuario' />}
                                    rules={[{ required: accion !== "pass", message: 'Por favor debe insertar el Nombre de usuario!' }]}
                                >
                                    <Input tabIndex={7}/>
                                </Form.Item>
                                <Form.Item
                                    hidden={accion === "mod"}
                                    autoComplete="off"
                                    name="contrasenna"
                                    label={<FormattedMessage id="label_pass" defaultMessage='Contraseña'/>}
                                    rules={[
                                        {required: accion !== "mod", message: 'Por favor debe escribir la contraseña!'},
                                        {min: 8, message: 'Debe introducir al menos 8 caracteres'}
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password autoComplete="new-password" tabIndex={10}/>
                                </Form.Item>

                            </Col>

                            {/* Segunda Columna */}
                            <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                    hidden={accion === "pass"}
                                    name="papell"
                                    label="Primer apellido"
                                    rules={[{ required: true, message: 'Por favor debe poner el primer apellido!' }]}
                                >
                                    <Input tabIndex={2}/>
                                </Form.Item>
                                <Form.Item
                                    hidden={accion === "pass"}
                                    name="correo"
                                    label={<FormattedMessage id="tabla_usuario_columna_correo" defaultMessage='Correo' />}
                                    rules={[{ required: accion !== "pass", message: 'Por favor debe poner el correo!' }, { validator: validateEmail }]}
                                >
                                    <Input tabIndex={5}/>
                                </Form.Item>

                                <Form.Item
                                    hidden={accion === "pass"}
                                    name="rol"
                                    label={<FormattedMessage id="tabla_usuario_columna_rol" defaultMessage='Rol' />}
                                    rules={[{ required: accion !== "pass", message: 'Por favor debe seleccionar el rol!' }]}
                                >
                                    <GlobalSelect loading={spinning} valueName={'rol'} data={roles} tabIndex={8}/>
                                </Form.Item>

                                <Form.Item
                                    hidden={accion === "mod"}
                                    name="confirm"
                                    label={<FormattedMessage id="label_confirm_pass" defaultMessage='Confirmar Contraseña' />}
                                    dependencies={['contrasenna']}
                                    hasFeedback
                                    rules={[
                                        { required: accion !== "mod", message: 'Por favor confirma la contraseña!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('contrasenna') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Las contraseñas no coinciden!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="new-password" tabIndex={11}/>
                                </Form.Item>
                            </Col>

                            {/* Tercera Columna */}
                            <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                    hidden={accion === "pass"}
                                    name="sapell"
                                    label="Segundo apellido"
                                    rules={[{ required: true, message: 'Por favor debe poner el segundo apellido!' }]}
                                >
                                    <Input tabIndex={3}/>
                                </Form.Item>
                                <Form.Item
                                    hidden={accion === "pass"}
                                    name="telefono"
                                    label={<FormattedMessage id="tabla_usuario_columna_telefono" defaultMessage='Teléfono' />}
                                    rules={[{ required: accion !== "pass", message: 'Por favor debe poner el teléfono!' }, { validator: validatePhoneNumber }]}
                                >
                                    <Input tabIndex={6}/>
                                </Form.Item>

                                <Form.Item
                                    hidden={accion === "pass"}
                                    name="direccion"
                                    label="Dirección"
                                    rules={[{ required: true, message: 'Por favor debe poner la dirección!' }]}
                                >
                                    <Input tabIndex={9}/>
                                </Form.Item>

                                <Form.Item hidden={accion === "pass"} noStyle name="activo">
                                    <Radio.Group style={{ marginTop: '30px' }}>
                                        <Radio value={1} tabIndex={12}>
                                            <FormattedMessage id="modulo_user_btn_activar" defaultMessage="Activar" />
                                        </Radio>
                                        <Radio value={0} tabIndex={13}>
                                            <FormattedMessage id="modulo_user_btn_desactivar" defaultMessage="Desactivar" />
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item {...tailLayout}>
                            <Space>
                                <Button icon={<CloseOutlined />} danger onClick={cancelar} tabIndex={14}>
                                    {t('global.cancel')}
                                </Button>
                                <Button icon={<SaveOutlined />} type="primary" htmlType="submit" tabIndex={15}>
                                    {t('global.save')}
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Spin>



    );
};

export default FormUsuario;