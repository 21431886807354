import React, { useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';
import CubaMap from '../mapa/CubaMap';
import { geocodeAddress } from './geocodeService';
import { useTranslation } from "react-i18next";

const SelectCoordinatesModal = ({ isVisible, onClose, onSelectCoordinates, data }) => {
    const { t } = useTranslation();
    const [coordinates, setCoordinates] = useState(null);

    const address = `${data.cmunicipality}. ${data.cprovince}`;

    useEffect(() => {
        if (isVisible) {
            setCoordinates(null);

            if (data.latitude && data.longitude) {
                // Si ya existen las coordenadas en `data`, las usamos directamente
                setCoordinates([data.latitude, data.longitude]);
            } else if (address) {
                // Si no, hacemos geocodificación para obtenerlas a partir de la dirección
                geocodeAddress(address).then((coords) => {
                    if (coords) {
                        setCoordinates(coords);
                    } else {
                        console.error("No se pudieron obtener coordenadas para la dirección proporcionada.");
                    }
                });
            }
        }
    }, [isVisible, address, data.latitude, data.longitude]);

    return (
        <Modal
            title={
                <div style={{
                    textAlign: 'center',
                    width: '100%',
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                }}>
                    {t('map.title')}
                </div>
            }
            open={isVisible}
            onCancel={onClose}
            footer={null}
            width="80%"
            style={{ top: 20, height: '80%', display: 'flex', flexDirection: 'column' }}
        >
            <p style={{
                textAlign: "center",
                margin: "10px 0",
                fontSize: "24px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                borderBottom: "2px solid #61768B",
                paddingBottom: "5px",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>
                {t('map.address')} : {data.address}
            </p>

            {/* Mostrar un spinner mientras se cargan las coordenadas */}
            {coordinates ? (
                <div style={{ flex: 1, overflow: 'auto' }}>
                    <CubaMap
                        center={coordinates}  // Centrar el mapa en las coordenadas
                        markerPosition={coordinates}  // Colocar el marcador en las coordenadas
                        onCoordinatesSelect={onSelectCoordinates}
                        data={data}
                        onClose={onClose}
                    />
                </div>
            ) : (
                <Spin tip={t('global.loading')} />
            )}
        </Modal>
    );
};

export default SelectCoordinatesModal;
