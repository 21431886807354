import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Image } from 'antd';

function Tracking() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const url = 'https://www.solved-pca.com/api/mzcub/playa/tracking/';
        const params = {
            apikey: '6I77Z9V01XV7IE3U5BM7XQ4X3D3PNK',
            data: JSON.stringify({
                enterprise: 'mzproduct',
                // numbers: [['AP000010', '00']]
                // numbers: [['AP004876', '']]
                numbers: [['AP000010', '']]
            }),
        };

        axios.get(url, { params })
            .then(response => {
                // Transforma el objeto recibido en un array de objetos
                const rawData = response.data;
                const formattedData = Object.keys(rawData).map(key => ({
                    key, // Usar el key original como identificador único
                    ...rawData[key]
                }));
                setData(formattedData);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    const columns = [
        {
            title: 'Número de Envío',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Destinatario',
            dataIndex: 'consignee',
            key: 'consignee',
        },
        {
            title: 'Descripción de Mercancía',
            dataIndex: 'goods',
            key: 'goods',
        },
        {
            title: 'Imagen',
            dataIndex: 'image',
            key: 'image',
            render: (text) => <Image src={`https://www.solved-pca.com/path/to/images/${text}`} alt="Package Image" width={100} />
        },
    ];

    return (
        <div>
            {error && <div>Error: {error.message}</div>}
            {loading ? (
                <div>Loading...</div>
            ) : (
                <Table dataSource={data} columns={columns} rowKey="key" />
            )}
        </div>
    );
}

export default Tracking;
