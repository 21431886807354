import {Form, Input, Radio, Space, Button, message, Spin} from "antd";
import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormChangeUsserPassword = ({}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const { t, i18n } = useTranslation();

    const [messageApi, contextHolder] = message.useMessage();

    const [spinning, setSpinning] = useState(false);

    const spinTitle = t('global.processing_data');

    let infor_user = (sessionStorage.getItem('user_mod'))? JSON.parse(sessionStorage.getItem('user_mod')):null;

    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm();

    useEffect(() => {
        // Resetea los campos del formulario al montarse el componente
        form.resetFields();

        if(id !== infor_user.idusuario)
               navigate('/usuarios');
        
    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
      };

    const cancelar = (values) => {
        navigate('/usuarios');
    };

    const onFinish = (values) => {
        setSpinning(true);
        Axios.post("mts/changePasswordUsser",
            {
              "idusuario": id,
              "contrasenna": values.contrasenna,
            },
            {
                headers: headers
              }
          ).then(response => {
             setSpinning(false);
             if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: t('info.success'),
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
                navigate('/usuarios');
            }else{
                messageApi.open({
                    type: 'error',
                    cocontent: t('info.error'),
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
        .catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                cocontent: t('info.error'),
                duration: 0,
            });
            setTimeout(messageApi.destroy, 2500);
        });
      };


      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div display="flex" align="middle">
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    {t('usser.change_password')}
                </p>
                {contextHolder}
                <Form
                    form={form}
                    autoComplete="on"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 10,
                    }}
                    name="form_in_modal"
                    initialValues={{
                        activo: 1,
                    }}
                >


                    <Form.Item
                        name="contrasenna"
                        label={t('usser.password')}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor debe escribir la contraseña!',
                            },
                            {min: 8, message: 'Debe introducir al menos 8 caracteres'}
                        ]}
                        hasFeedback
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label={t('usser.confirm_password')}
                        dependencies={['contrasenna']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Por favor confirma la  contraseña!',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('contrasenna') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Las contraseñas no coinciden!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Space>
                            <Button icon={<CloseOutlined />} danger onClick={cancelar}>
                                {t('global.cancel')}
                            </Button>
                            <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
                                {t('global.save')}
                            </Button>
                        </Space>
                    </Form.Item>

                </Form>

            </div>
        </Spin>

    );
};

export default FormChangeUsserPassword;