import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  message,
  Select,
  Typography,
} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import useAutoFocus from "../generales/hooks/useAutoFocus";
import Logo from "./images/logo.png";
import flags_us from "./images/flags_us.png";
import flags_es from "./images/flags_spanish.png";
import { useTranslation } from "react-i18next";

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_APPI_ADDRESS,
});

const FormLoginHT = () => {
  const autoFocusInput = useAutoFocus();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [token, setToken] = useState(null);
  const [headers, setHeaders] = useState(null);

  const [idioma, setIdioma] = useState("es");
  const { t, i18n } = useTranslation();

  // Cambiar el idioma
  const changeLanguage = (lng) => {
    // console.log(lng);
    i18n.changeLanguage(lng);
    setIdioma(lng);
    sessionStorage.setItem("idioma", lng);
  };

  useEffect(() => {
    const userLang =
      sessionStorage.getItem("idioma") ||
      navigator.language ||
      navigator.userLanguage; // Detectar idioma
    const preferredLang = userLang.startsWith("es") ? "es" : "en"; // Ajustar a 'es' o 'en'
    changeLanguage(preferredLang); // Cambiar idioma
  }, []);

  useEffect(() => {
    async function getToken() {
      try {
        const response = await Axios.post("/login", {
          username: process.env.REACT_APP_USER,
          password: process.env.REACT_APP_PASS,
        });
        let aux = response.data.token;
        sessionStorage.setItem("token", aux);
        setToken(aux);
        setHeaders({
          accept: "*/*",
          Authorization: "Bearer " + aux,
          "Content-Type": "application/json",
        });
      } catch {
        setToken(null);
        setHeaders(null);
        sessionStorage.removeItem("token");
      }
    }
    getToken();
  }, []);

  const permisosPorRol = (info_user) => {
    let funcionalidades = [];
    switch (info_user.idrol) {
      //Administrador
      case "1":
        funcionalidades = [
          "menu_usuarios",
          "menu_persona",
          "menu_paquetes",
          "menu_entregas",
          "menu_vehiculos",
          "menu_variables",
          "menu_auditoria",
          "menu_mzproducts",
        ];
        break;
      //Coordinador de rutas
      case "2":
        funcionalidades = ["menu_entregas", "menu_vehiculos"];
        break;
      //Operador de coordenadas
      case "3":
        funcionalidades = ["menu_paquetes"];
        break;
      //Repartidor
      case "4":
        funcionalidades = [];
        // funcionalidades = ["menu_paquetes"];
        break;
      default:
        funcionalidades = ["menu_auditoria"];
        break;
    }
    sessionStorage.setItem("funcionalidades", JSON.stringify(funcionalidades));
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (values.user.trim() !== "" && values.pass.trim() !== "") {
      try {
        const response = await Axios.post(
          "mts/login",
          {
            correo: values.user.trim(),
            contrasenna: values.pass.trim(),
          },
          { headers: headers }
        );
        setLoading(false);
        if (response.data.success) {
          let info_user = response.data.data[0];
          sessionStorage.setItem("idusuario", info_user.idusuario);
          sessionStorage.setItem("info_user", JSON.stringify(info_user));
          permisosPorRol(info_user);
          window.location = "/principal";
          messageApi.success(response.data.message);
        } else {
          messageApi.error(response.data.message);
        }
      } catch {
        setLoading(false);
        sessionStorage.clear();
        messageApi.error(t("global.server_connection")); // Usando traducción
      }
    } else {
      setLoading(false);
      messageApi.error(t("global.missing_credentials")); // Usando traducción
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(to bottom, #61768B, #f9f9f9)",
      }}
    >
      {contextHolder}
      <Row justify="center" style={{ width: "100%" }}>
        <Col
          xs={22}
          sm={18}
          md={12}
          lg={8}
          xl={6}
          style={{
            padding: "20px",
            background: "linear-gradient(to bottom, #38516E, #f9f9f9)",
            borderRadius: "35px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "150px", borderRadius: "10px" }}
            />
          </div>
          <Typography.Title
            level={3}
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            {t("login.title")} {/* Usando la traducción */}
          </Typography.Title>
          <Form
            form={form}
            name="login_form"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="user"
              rules={[
                { required: true, message: t("error.email_required") },
                { type: "email", message: t("error.invalid_email") },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t("login.email")}
                ref={autoFocusInput}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="pass"
              rules={[
                { required: true, message: t("error.password_required") },
                { min: 8, message: t("error.password_min_length") },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder={t("login.password")}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                block
              >
                {t("login.submit_button")}
              </Button>
            </Form.Item>
          </Form>

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Select
              value={idioma}
              onChange={changeLanguage}
              style={{
                width: 130,
                textAlign: "left",
              }}
              options={[
                {
                  value: "es",
                  label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        role="img"
                        aria-label="Español"
                        style={{ marginRight: 8 }}
                      >
                        🇪🇸
                      </span>
                      Español
                    </div>
                  ),
                },
                {
                  value: "en",
                  label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        role="img"
                        aria-label="Inglés"
                        style={{ marginRight: 8 }}
                      >
                        🇺🇸
                      </span>
                      Inglés
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FormLoginHT;
