import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Select, Spin, Space, Row, Col, Tooltip, Input} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {MdAddHome} from "react-icons/md";
import GlobalSelect from "../../generales/GlobalSelect";
import {
    CarOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    EyeOutlined,
    PlusOutlined,
    SearchOutlined,
    TrademarkOutlined
} from '@ant-design/icons';
import {
    AiFillAlert,
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {IoGolf, IoReload} from "react-icons/io5";
import Search from "antd/es/input/Search";
import {useTranslation} from "react-i18next";
import _ from "lodash";


export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});
const {Option} = Select;


const Vehiculo = () => {

    if (sessionStorage.getItem('sessionDataStorage'))
        sessionStorage.removeItem('sessionDataStorage');

    let info_user = JSON.parse(sessionStorage.getItem('info_user'));

    const {t, i18n} = useTranslation();
    const [bottom, setBottom] = useState('bottomCenter');
    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState(t('global.loading'));
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading] = useState(false);
    const [searchValue, setSearchValue] = useState(''); // Estado para controlar el valor del input
    const [selectedRow, setSelectedRow] = useState([]); // Estado para la fila seleccionada
    const [selectedRepartidorRow, setSelectedRepartidorRow] = useState([]); // Estado para la fila seleccionada
    const [isVerVisible, setIsVerVisible] = useState(false); // Estado del modal
    const [repartidorVisible, setRepartidorVisible] = useState(false); // Estado del modal
    const [repartidor, setRepartidor] = useState([]);
    const [estadoVehiculo, setEstadoVehiculo] = useState([]);
    const [tipoVehiculo, setTipoVehiculo] = useState([]);
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);

    const [selectedEstadoRow, setSelectedEstadoRow] = useState([]);
    const [estadoVisible, setEstadoVisible] = useState(false);
    // const [estado, setEstado] = useState([]);

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        recargar();
    }, []);

    const filterData = data => formatter => data.map(item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('vehicle.marca')}</p>,
            dataIndex: 'marca',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.marca), _.isEqual),
            onFilter: (value, record) => record.marca.startsWith(value),
            sorter: (a, b) => a.marca.localeCompare(b.marca),
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('vehicle.modelo')}</p>,
            dataIndex: 'modelo',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.modelo), _.isEqual),
            onFilter: (value, record) => record.modelo.startsWith(value),
            sorter: (a, b) => a.modelo.localeCompare(b.modelo),
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('vehicle.matricula')}</p>,
            dataIndex: 'matricula',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.matricula), _.isEqual),
            onFilter: (value, record) => record.matricula.startsWith(value),
            sorter: (a, b) => a.matricula.localeCompare(b.matricula),
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('vehicle.capacidad_carga')}</p>,
            dataIndex: 'capacidad_carga',
            width: 240,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.capacidad_carga), _.isEqual),
            onFilter: (value, record) => record.capacidad_carga.startsWith(value),
            sorter: (a, b) => a.capacidad_carga - b.capacidad_carga,
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('vehicle.longitud_carga')}</p>,
            dataIndex: 'longitud_carga',
            width: 240,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.longitud_carga), _.isEqual),
            onFilter: (value, record) => record.longitud_carga.startsWith(value),
            sorter: (a, b) => a.longitud_carga - b.longitud_carga,
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('vehicle.ancho_carga')}</p>,
            dataIndex: 'ancho_carga',
            width: 230,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.ancho_carga), _.isEqual),
            onFilter: (value, record) => record.ancho_carga.startsWith(value),
            sorter: (a, b) => a.ancho_carga - b.ancho_carga,
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('vehicle.altura_carga')}</p>,
            dataIndex: 'altura_carga',
            width: 230,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.altura_carga), _.isEqual),
            onFilter: (value, record) => record.altura_carga.startsWith(value),
            sorter: (a, b) => a.altura_carga - b.altura_carga,
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('vehicle.tipo_vehiculo')}</p>,
            dataIndex: 'tipo_vehiculo',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.tipo_vehiculo), _.isEqual),
            onFilter: (value, record) => record.tipo_vehiculo.startsWith(value),
            sorter: (a, b) => a.tipo_vehiculo.localeCompare(b.tipo_vehiculo),
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('vehicle.estado')}</p>,
            dataIndex: 'estado_vehiculo',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.estado_vehiculo), _.isEqual),
            onFilter: (value, record) => record.estado_vehiculo.startsWith(value),
            sorter: (a, b) => a.estado_vehiculo.localeCompare(b.estado_vehiculo),
        }
    ];


    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: data.length, // Asegúrate de tener el total de elementos aquí
    });

    const handleTableChange = (newPagination) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    const [repartidorParams, setRepartidorParams] = useState({});
    const [formCambiarRepartidor] = Form.useForm();

    const showModalRepartidor = async () => {
        formCambiarRepartidor.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        setSelectedRepartidorRow(row); // Guardar la fila seleccionada
        setRepartidorVisible(true); // Mostrar el modal
    };

    const handleCancelRepartidor = () => {
        formCambiarRepartidor.resetFields();
        setRepartidorVisible(false); // Ocultar el modal
    };
    const handleFormChangeRepartidor = (changedValues, allValues) => {
        allValues = {
            idusuario: allValues.idusuario
        };
        //console.log(allValues);
        setRepartidorParams(allValues);  // Actualizar los parámetros de búsqueda conforme se llena el formulario
    };

    const handleOkAsignarRepartidor = () => {
        repartidorParams.idvehiculo = selectedRepartidorRow.idvehiculo;
        setSpinTitle(t('global.processing_data'));
        setSpinning(true);
        let message;
        Axios.post('sd/asignarRepartidor', repartidorParams, {headers})  // Se envían los parámetros de búsqueda
            .then(response => {
                // console.log(response.data.message);
                message = response.data.message;
                setSpinning(false);
                if (response.data.success) {
                    setRepartidorVisible(false);  // Cerrar el modal
                    messageApi.open({
                        type: 'success',
                        content: message,
                        duration: 0,
                    });
                    setTimeout(messageApi.destroy, 2500);
                    recargar();
                } else {
                    messageApi.open({type: 'error', content: message});
                    setTimeout(messageApi.destroy, 3500);
                }

            })
            .catch(() => {
                setSpinning(false);
                messageApi.open({type: 'error', content: message});
                setTimeout(messageApi.destroy, 3500);
            });
    };

    const [estadoParams, setEstadoParams] = useState({});
    const [formCambiarEstado] = Form.useForm();

    const showModalEstado = async () => {
        formCambiarEstado.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        setSelectedEstadoRow(row); // Guardar la fila seleccionada
        setEstadoVisible(true); // Mostrar el modal
    };

    const handleCancelEstado = () => {
        formCambiarEstado.resetFields();
        setEstadoVisible(false); // Ocultar el modal
    };
    const handleFormChangeEstado = (changedValues, allValues) => {
        allValues = {
            idestadovehiculo: allValues.idestadovehiculo
        };
        setEstadoParams(allValues);  // Actualizar los parámetros de búsqueda conforme se llena el formulario
    };

    const handleOkAsignarEstado = () => {
        estadoParams.idvehiculo = selectedEstadoRow.idvehiculo;
        setSpinTitle(t('global.processing_data'));
        setSpinning(true);
        let message;
        Axios.post('sd/cambiarEstadoVehiculo', estadoParams, {headers})  // Se envían los parámetros de búsqueda
            .then(response => {
                // console.log(response.data.message);
                message = response.data.message;
                setSpinning(false);
                if (response.data.success) {
                    setEstadoVisible(false);  // Cerrar el modal
                    messageApi.open({
                        type: 'success',
                        content: message,
                        duration: 0,
                    });
                    setTimeout(messageApi.destroy, 2500);
                    recargar();
                } else {
                    messageApi.open({type: 'error', content: message});
                    setTimeout(messageApi.destroy, 3500);
                }

            })
            .catch(() => {
                setSpinning(false);
                messageApi.open({type: 'error', content: message});
                setTimeout(messageApi.destroy, 3500);
            });
    };

    const showModalVer = async () => {
        const row = await getInfoForm(selectedRowKeys[0]);
        setSelectedRow(row); // Guardar la fila seleccionada
        setIsVerVisible(true); // Mostrar el modal
    };

    const handleCancelVer = () => {
        setIsVerVisible(false); // Ocultar el modal
    };

    const cargarRepartidor = () => {
        setSpinning(true);
        let aux = [];
        // let start = 0;
        // let limit = 1000;

        Axios.post("mts/getUssers",
            {
                // "start": start,
                // "limit": limit,
                "filtros": {
                    "idrol": 4
                }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        id: response.data.data[i].idusuario,
                        // idrepartidor:response.data.data[i].idusuario,
                        nombre: response.data.data[i].fullname
                    });
                }
            }
            setRepartidor(aux);
        }).catch(error => {
            setSpinning(false);
            setRepartidor(aux);
        })
    };

    const cargarEstadoVehiculo = () => {
        setSpinning(true);
        let aux = [];
        // let start = 0;
        // let limit = 1000;

        Axios.post("sd/getEstadoVehiculo",
            {
                // "start": start,
                // "limit": limit,
                // "filtros":{
                //     "idrol": 4
                // }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        id: response.data.data[i].idestadovehiculo,
                        nombre: response.data.data[i].denominacion
                    });
                }
            }
            setEstadoVehiculo(aux);
        }).catch(error => {
            setSpinning(false);
            setEstadoVehiculo(aux);
        })
    };

    const cargarTipoVehiculo = () => {
        setSpinning(true);
        let aux = [];
        // let start = 0;
        // let limit = 1000;

        Axios.post("sd/getTipoVehiculo",
            {
                // "start": start,
                // "limit": limit,
                "filtros": {
                    "activo": 1
                }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        id: response.data.data[i].idtipovehiculo,
                        nombre: response.data.data[i].denominacion
                    });
                }
            }
            setTipoVehiculo(aux);
        }).catch(error => {
            setSpinning(false);
            setTipoVehiculo(aux);
        })
    };

    const recargar = () => {
        cargarEstadoVehiculo();
        cargarRepartidor();
        cargarTipoVehiculo();

        //limpiar la busqueda del componente Search
        setSearchValue('');

        setSpinTitle(t('global.loading'));
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        let start = 0;
        let limit = 1000;

        Axios.post("sd/getVehiculos",
            {
                // "start": start,
                // "limit": limit,
                // "filtro":{
                //     "idrol": 1
                // }
            },
            {
                headers: headers
            }
        ).then(response => {
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        key: response.data.data[i].idvehiculo,
                        idvehiculo: response.data.data[i].idvehiculo,
                        marca: response.data.data[i].marca,
                        modelo: response.data.data[i].modelo,
                        matricula: response.data.data[i].matricula,
                        capacidad_carga: response.data.data[i].capacidad_carga,
                        longitud_carga: response.data.data[i].longitud_carga,
                        ancho_carga: response.data.data[i].ancho_carga,
                        altura_carga: response.data.data[i].altura_carga,
                        comentarios: response.data.data[i].comentarios,
                        fullname: response.data.data[i].fullname,
                        idusuario: response.data.data[i].idusuario,
                        idestadovehiculo: response.data.data[i].idestadovehiculo,
                        estado_vehiculo: response.data.data[i].estado_vehiculo,
                        tipo_vehiculo: response.data.data[i].tipo_vehiculo,
                        idtipovehiculo: response.data.data[i].idtipovehiculo,
                    });
                }
            }
            // console.table(aux);
            setData(aux);
            setOriginal(aux);
            setSpinning(false);
        }).catch(error => {
            setSpinning(false);
        })
    };

    const getInfoForm = async (id) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].idvehiculo === id) {
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('sessionDataStorage', JSON.stringify(row));
    };

    const eliminar = (e) => {
        Modal.confirm({
            title: t('vehicle.delete'),
            cancelText: t('global.cancel'),
            okText: t('global.save'),
            okType: 'primary',
            content: (
                <div>
                    <span>{t('vehicle.delete_data')}</span>
                </div>
            ),
            onOk() {
                setSpinTitle(t('global.processing_data'));
                setSpinning(true);
                Axios.delete('sd/deleteVehiculos',
                    {
                        data: {
                            "idvehiculo": selectedRowKeys,
                            "nombreusuario": info_user.nombreusuario
                        },
                        headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if (response.data.success) {
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    } else {
                        messageApi.open({
                            type: 'error',
                            content: t('info.error'),
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: t('info.error'),
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    });
            },
        });
    };

    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if (value === "" || value === null) {
            setData(original);
        } else {
            let aux = [];
            original.forEach(function (option) {
                if (option.matricula.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelectedRowKeys.length === 1) {
            const selectedRow = data.find(row => row.key === newSelectedRowKeys[0]);
            if (selectedRow) {
                setDeleteButtonDisabled(selectedRow.idestadovehiculo != 1 && selectedRow.idestadovehiculo != 4);
            }
        } else {
            setDeleteButtonDisabled(true);
        }
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate = async (record) => {
        setSelectedRowKeys([record.key]);
        form.resetFields();
        let row = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].idvehiculo === record.key) {
                row = data[i];
            }
        }

        sessionStorage.setItem('sessionDataStorage', JSON.stringify(row));
        navigate(`/editVehiculo/${record.key}`)

    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [formSearch] = Form.useForm();

    const showModal = () => {
        formSearch.resetFields();
        setIsModalVisible(true);
    };

    const handleFormChange = (changedValues, allValues) => {
        allValues = {
            "filtros":
                {
                    marca: allValues.marca,
                    modelo: allValues.modelo,
                    matricula: allValues.matricula,
                    capacidad_carga: allValues.capacidad_carga,
                    longitud_carga: allValues.longitud_carga,
                    ancho_carga: allValues.ancho_carga,
                    altura_carga: allValues.altura_carga,
                    idusuario: allValues.idusuario,
                    idestadovehiculo: allValues.idestadovehiculo,
                    idtipovehiculo: allValues.idtipovehiculo,
                    comentarios: allValues.comentarios
                }
        };
        //console.log(allValues);
        setSearchParams(allValues);  // Actualizar los parámetros de búsqueda conforme se llena el formulario
    };

    const handleOk = () => {
        setSpinTitle(t('global.processing_data'));
        setSpinning(true);
        Axios.post('sd/getVehiculos', searchParams, {headers})  // Se envían los parámetros de búsqueda
            .then(response => {
                setSpinning(false);
                if (response.data.data.length > 0) {
                    const aux = response.data.data.map(resultado => ({
                        key: resultado.idvehiculo,
                        idvehiculo: resultado.idvehiculo,
                        marca: resultado.marca,
                        modelo: resultado.modelo,
                        matricula: resultado.matricula,
                        capacidad_carga: resultado.capacidad_carga,
                        longitud_carga: resultado.longitud_carga,
                        ancho_carga: resultado.ancho_carga,
                        altura_carga: resultado.altura_carga,
                        comentarios: resultado.comentarios,
                        estado_vehiculo: resultado.estado_vehiculo,
                        tipo_vehiculo: resultado.tipo_vehiculo,
                        fullname: resultado.fullname,
                    }));
                    setData(aux);  // Actualizar la tabla con los resultados de búsqueda
                } else {
                    message.warning('No se encontraron resultados.');
                    setData([]);
                }
                setIsModalVisible(false);  // Cerrar el modal después de realizar la búsqueda
            })
            .catch(() => {
                setSpinning(false);
                message.error('Error al realizar la búsqueda.');
            });
    };


    const handleCancel = () => {
        formSearch.resetFields();
        setIsModalVisible(false);
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                //alignItems: 'center',
                padding: '10px'
            }}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    {t('vehicle.title')}
                </p>
                {contextHolder}
                <div
                    style={{
                        marginBottom: 10,
                    }}
                >
                    {contextHolder}
                    <Space direction='horizontal'>
                        <Link to="/addVehiculo">
                            <Tooltip title={t('vehicle.add')}>
                                <Button style={{marginRight: "0px", marginLeft: "10px"}} type="primary" shape="circle"
                                        className="button" icon={<PlusOutlined/>} disabled={loading}>

                                </Button>
                            </Tooltip>
                        </Link>
                        <Link to={`/editVehiculo/${selectedRowKeys[0]}`}>
                            <Tooltip title={t('vehicle.edit')}>
                                <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                        className="button" icon={<AiFillEdit/>} onClick={modificar}
                                        disabled={loading || selectedRowKeys.length !== 1}>

                                </Button>
                            </Tooltip>
                        </Link>
                        <Tooltip title="Asignar Repartidor">
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<TrademarkOutlined/>} onClick={showModalRepartidor}
                                    disabled={!hasSelected || loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>
                        <Tooltip title="Cambiar estado">
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<IoGolf/>} onClick={showModalEstado}
                                    disabled={!hasSelected || loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>
                        <Tooltip title="Ver detalles">
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<EyeOutlined/>} onClick={showModalVer}
                                    disabled={!hasSelected || loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>
                        <Tooltip title={t('vehicle.delete')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle" danger
                                    className="button" icon={<AiFillDelete/>} onClick={eliminar}
                                    disabled={deleteButtonDisabled}>

                            </Button>
                        </Tooltip>

                        <Tooltip title={t('vehicle.search')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<SearchOutlined/>} onClick={showModal}></Button>
                        </Tooltip>

                        <Tooltip title={t('vehicle.update')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<IoReload/>} onClick={recargar}
                                    loading={loading || loading}>

                            </Button>
                        </Tooltip>

                        <Search
                            placeholder={t('vehicle.search')}
                            enterButton
                            style={{
                                width: 230,
                            }}
                            size="middle"
                            onSearch={onSearch}
                            value={searchValue} // El valor del input es controlado por el estado
                            onChange={(e) => {
                                setSearchValue(e.target.value); // Actualiza el estado con lo que se escribe
                                filterLocal(e.target.value);
                            }}
                        />
                    </Space>

                    <Modal
                        title={<div style={{textAlign: 'center', width: '100%'}}>Cambiar Repartidor</div>}
                        open={repartidorVisible}
                        onCancel={handleCancelRepartidor}
                        onOk={() => {
                            formCambiarRepartidor
                                .validateFields() // Valida todos los campos
                                .then((values) => {
                                    // Si la validación es exitosa, puedes ejecutar tu lógica
                                    handleOkAsignarRepartidor(values); // Pasa los valores validados a tu función
                                })
                                .catch((info) => {
                                    // Si hay errores de validación, no hace nada
                                    console.log('Validación fallida:', info);
                                });
                        }}
                        okText="Guardar"
                        cancelText="Cancelar"
                    >
                        <Form form={formCambiarRepartidor} onValuesChange={handleFormChangeRepartidor}
                              layout="vertical">
                            <Form.Item
                                name="idusuario"
                                label={t('vehicle.repartidor')}
                                rules={[{required: true, message: 'Por favor, seleccione un repartidor!'}]}
                            >
                                <GlobalSelect loading={spinning} valueName={'idusuario'} data={repartidor}/>
                            </Form.Item>
                        </Form>
                    </Modal>

                    <Modal
                        title={<div style={{textAlign: 'center', width: '100%'}}>Cambiar Estado</div>}
                        open={estadoVisible}
                        onCancel={handleCancelEstado}
                        onOk={() => {
                            formCambiarEstado
                                .validateFields() // Valida todos los campos
                                .then((values) => {
                                    // Si la validación es exitosa, puedes ejecutar tu lógica
                                    handleOkAsignarEstado(values); // Pasa los valores validados a tu función
                                })
                                .catch((info) => {
                                    // Si hay errores de validación, no hace nada
                                    console.log('Validación fallida:', info);
                                });
                        }}
                        okText="Guardar"
                        cancelText="Cancelar"
                    >
                        <Form form={formCambiarEstado} onValuesChange={handleFormChangeEstado} layout="vertical">
                            <Form.Item
                                name="idestadovehiculo"
                                label={t('vehicle.estado')}
                                rules={[{required: true, message: 'Por favor, seleccione un estado!'}]}
                            >
                                <GlobalSelect
                                    loading={spinning}
                                    valueName={'idestadovehiculo'}
                                    data={estadoVehiculo.filter(item => item.id !== "3")} // Excluir el id 3 - En Uso
                                />
                            </Form.Item>
                        </Form>
                    </Modal>

                    <Modal
                        title={<div style={{textAlign: 'center', width: '100%'}}>Detalles del Vehículo</div>}
                        open={isVerVisible}
                        onCancel={handleCancelVer}
                        footer={
                            <div style={{textAlign: 'center', width: '100%'}}>
                                <Button type="primary" onClick={handleCancelVer} style={{marginRight: 8}}>
                                    OK
                                </Button>
                            </div>
                        }
                    >
                        {selectedRow && (
                            <div>
                                <p><strong>Marca:</strong> {selectedRow.marca}</p>
                                <p><strong>Modelo:</strong> {selectedRow.modelo}</p>
                                <p><strong>Matricula:</strong> {selectedRow.matricula}</p>
                                <p><strong>Capacidad de carga:</strong> {selectedRow.capacidad_carga}</p>
                                <p><strong>Longitud carga:</strong> {selectedRow.longitud_carga}</p>
                                <p><strong>Ancho carga:</strong> {selectedRow.ancho_carga}</p>
                                <p><strong>Altura carga:</strong> {selectedRow.altura_carga}</p>
                                <p><strong>Repartidor:</strong> {selectedRow.fullname}</p>
                                <p><strong>Tipo Vehículo:</strong> {selectedRow.tipo_vehiculo}</p>
                                <p><strong>Estado:</strong> {selectedRow.estado_vehiculo}</p>
                                <p><strong>Comentarios:</strong> {selectedRow.comentarios}</p>
                            </div>
                        )}
                    </Modal>

                    <Modal
                        title={<div style={{textAlign: 'center', width: '100%'}}>{t('global.search')}</div>}
                        open={isModalVisible}
                        width={'700px'}
                        //onOk={handleOk}
                        onCancel={handleCancel}
                        footer={
                            <div style={{textAlign: 'center', width: '100%'}}>
                                <Button onClick={handleCancel} style={{marginRight: 8}}>
                                    Cancelar
                                </Button>
                                <Button type="primary" onClick={handleOk}>
                                    Aceptar
                                </Button>
                            </div>
                        }
                    >
                        <Form layout="vertical" form={formSearch} onValuesChange={handleFormChange}>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        name="marca"
                                        label={t('vehicle.marca')}>
                                        <Input placeholder="Ingrese la marca"/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="modelo"
                                        label={t('vehicle.modelo')}>
                                        <Input placeholder="Ingrese el modelo"/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="matricula"
                                        label={t('vehicle.matricula')}>
                                        <Input placeholder="Ingrese la matrícula"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        name="capacidad_carga"
                                        label={t('vehicle.capacidad_carga')}>
                                        <Input placeholder="Ingrese la capacidad de carga"/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="longitud_carga"
                                        label={t('vehicle.longitud_carga')}>
                                        <Input placeholder="Ingrese la longitud de carga"/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="ancho_carga"
                                        label={t('vehicle.ancho_carga')}>
                                        <Input placeholder="Ingrese la ancho de carga"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        name="altura_carga"
                                        label={t('vehicle.altura_carga')}>
                                        <Input placeholder="Ingrese la altura de carga"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="idtipovehiculo"
                                        label={t('vehicle.tipo_vehiculo')}
                                    >
                                        <GlobalSelect loading={spinning} valueName={'idtipovehiculo'}
                                                      data={tipoVehiculo}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="idusuario"
                                        label={t('vehicle.repartidor')}
                                    >
                                        <GlobalSelect loading={spinning} valueName={'idusuario'} data={repartidor}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="idestadovehiculo"
                                        label={t('vehicle.estado')}
                                    >
                                        <GlobalSelect loading={spinning} valueName={'idestadovehiculo'}
                                                      data={estadoVehiculo}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="comentarios"
                                        label={t('vehicle.comentarios')}>
                                        <Input.TextArea placeholder="Ingrese la nota adicional"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                    </Modal>

                </div>

                <Row>
                    <Col span={24}>
                        <Table size="small"
                            // scroll={{
                            //     y: 350,
                            // }}
                               bordered={true}
                               pagination={{
                                   position: [bottom],
                                   current: pagination.current,
                                   pageSize: pagination.pageSize,
                                   showSizeChanger: true,
                                   pageSizeOptions: ['10', '20', '50', '100'],
                                   showTotal: (total) => `Total ${total} elementos`, // Texto que muestra el total de elementos
                               }}
                               rowSelection={rowSelection}
                               columns={columns} dataSource={data}
                               onRow={(record) => {
                                   return {
                                       onDoubleClick: async () => {
                                           // selectRowUpdate(record);
                                       }
                                   }
                               }}
                               expandable={{
                                   expandedRowRender: (record) => (
                                       <p
                                           style={{
                                               margin: 0,
                                               marginLeft: 80,
                                               color: '#38516D'
                                           }}
                                       >
                                           <b>Repartidor : </b>
                                           {record.fullname}

                                           <br/><br/>

                                           <b>Comentarios : </b>
                                           {record.comentarios}

                                       </p>


                                   ),
                                   rowExpandable: (record) => record.comentarios !== 'Not Expandable',
                               }}
                        />
                    </Col>
                </Row>


            </div>
        </Spin>
    );
};
export default Vehiculo;