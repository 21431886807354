import {Form, Select} from "antd";
import { useState } from "react";
import {useTranslation} from "react-i18next";

const GlobalSelect = ({loading, valueName, data, onSelect, ...props }) => {

    const {t, i18n} = useTranslation();
    const formInstance = Form.useFormInstance();
    const selectValue = Form.useWatch(valueName, formInstance);

    const onChangeFunction = (value, option) => {
        formInstance.setFieldValue(valueName, value);
        if(onSelect)onSelect(value, option);
    }

    return (
        <Select
            loading={loading}
            placeholder={t('global.select')}
            showSearch
            allowClear
            optionFilterProp="label"
            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
                optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
            }
            options={data.map((item) => ({ ...item, value: item.id, label: item.nombre }))}
            // onChange={onChangeFunction}
            {...props}
            onSelect={onChangeFunction}
            value={selectValue}
        >
        </Select>
    )
}
 
export default GlobalSelect;