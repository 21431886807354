import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Select, Spin, Space, Row, Col, Tooltip} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {EyeOutlined, FormOutlined} from '@ant-design/icons';
import {IoPersonAdd, IoPersonSharp, IoReload} from "react-icons/io5";
import Search from "antd/es/input/Search";
import _ from "lodash";
import {useTranslation} from "react-i18next";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});
const {Option} = Select;

const Usuarios = () => {

    if (sessionStorage.getItem('user_mod'))
        sessionStorage.removeItem('user_mod');

    const [bottom, setBottom] = useState('bottomCenter');
    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando usuarios...");
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [idioma, setIdioma] = useState("es");
    const {t, i18n} = useTranslation();
    const [searchCorreoValue, setSearchCorreo] = useState(''); // Estado para controlar el valor del input
    const [selectedRow, setSelectedRow] = useState([]); // Estado para la fila seleccionada
    const [isVerVisible, setIsVerVisible] = useState(false); // Estado del modal
    // Cambiar el idioma
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setIdioma(lng);
        sessionStorage.setItem("idioma", lng);
    };

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: data.length, // Asegúrate de tener el total de elementos aquí
    });

    const handleTableChange = (newPagination) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    useEffect(() => {
        recargar();
    }, []);

    useEffect(() => {
        const userLang = sessionStorage.getItem("idioma") || navigator.language || navigator.userLanguage; // Detectar idioma
        const preferredLang = userLang.startsWith('es') ? 'es' : 'en'; // Ajustar a 'es' o 'en'
        changeLanguage(preferredLang); // Cambiar idioma
    }, []);

    const filterData = data => formatter => data.map(item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            //title:<FormattedMessage id="tabla_usuario_columna_usuario" defaultMessage='Usuario' />,
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('usser.col_usser')}</p>,
            dataIndex: 'nombreusuario',
            width: 120,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.nombreusuario), _.isEqual),
            onFilter: (value, record) => record.nombreusuario.startsWith(value),
        },
        {
            // title:<FormattedMessage id="tabla_usuario_columna_correo" defaultMessage='Correo' />,
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('usser.col_email')}</p>,
            dataIndex: 'correo',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.correo), _.isEqual),
            onFilter: (value, record) => record.correo.startsWith(value),
        }, {
            // title: <FormattedMessage id="tabla_usuario_columna_telefono" defaultMessage='Teléfono' />,
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('usser.col_phone')}</p>,
            dataIndex: 'telefono',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.telefono), _.isEqual),
            onFilter: (value, record) => record.telefono.startsWith(value),
        }, {
            // title: <FormattedMessage id="tabla_usuario_columna_rol" defaultMessage='Rol' />,
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('usser.col_role')}</p>,
            dataIndex: 'rol',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.rol), _.isEqual),
            onFilter: (value, record) => record.rol.startsWith(value),
        }, {
            // title: <FormattedMessage id="tabla_usuario_columna_registro" defaultMessage='Registro' />,
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('usser.col_registration')}</p>,
            dataIndex: 'fecharegistro',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.fecharegistro), _.isEqual),
            onFilter: (value, record) => record.fecharegistro.startsWith(value),
        }, {
            // title: <FormattedMessage id="tabla_usuario_columna_actualizado" defaultMessage='Actualizado' />,
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('usser.col_updated')}</p>,
            dataIndex: 'fechaactualizacion',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.fechaactualizacion), _.isEqual),
            onFilter: (value, record) => record.fechaactualizacion.startsWith(value),
        }, {
            // title: <FormattedMessage id="tabla_usuario_columna_activo" defaultMessage='Activo' />,
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('usser.col_active')}</p>,
            dataIndex: 'activo',
            render: (text) => (text === "1") ? <IoPersonSharp size={20} color={'#0d6efd'}/> :
                <IoPersonSharp size={20} color={'red'}/>,
            // filterSearch: true,
            // filters: _.uniqWith(filterData(data)(i => i.activo), _.isEqual),
            // onFilter: (value, record) => record.activo.startsWith( value ),
        }
    ];

    const recargar = () => {

        setSearchCorreo('');

        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        // let start = 0;
        // let limit = 1000;


        Axios.post("mts/getUssers",
            {
                // "start": start,
                // "limit": limit,
                // "filtros":{
                //     "idrol": "",
                // }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        key: response.data.data[i].idusuario,
                        idusuario: response.data.data[i].idusuario,
                        nombreusuario: response.data.data[i].nombreusuario,
                        nombre: response.data.data[i].nombre,
                        papell: response.data.data[i].papell,
                        sapell: response.data.data[i].sapell,
                        carnet: response.data.data[i].carnet,
                        direccion: response.data.data[i].direccion,
                        correo: response.data.data[i].correo,
                        contrasenna: response.data.data[i].contrasenna,
                        activo: response.data.data[i].activo,
                        eliminado: response.data.data[i].eliminado,
                        fecharegistro: response.data.data[i].fecharegistro,
                        fechaactualizacion: response.data.data[i].fechaactualizacion,
                        idrol: response.data.data[i].idrol,
                        rol: response.data.data[i].rol,
                        telefono: response.data.data[i].telefono,
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        }).catch(error => {
            setSpinning(false);
        })
    };

    const getInfoForm = async (id) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].idusuario === id) {
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('user_mod', JSON.stringify(row));
    };

    const eliminar = (e) => {
        Modal.confirm({
            title: 'Eliminar usuarios!',
            cancelText: 'Cancelar',
            okText: 'Aceptar',
            okType: 'primary',
            content: (
                <div>
                    <span>¿Desea eliminar los usuarios seleccionados?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Eliminando usuarios");
                setSpinning(true);
                Axios.delete('mts/deleteUsser',
                    {
                        data: {
                            "idusuario": selectedRowKeys
                        },
                        headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if (response.data.success) {
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    } else {
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar usuarios',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar usuarios',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);


                    });
            },
        });
    };

    function activacion(e, activar) {

        Modal.confirm({
            title: (activar) ? 'Activar usuario' : 'Desactivar usuario',
            cancelText: 'Cancelar',
            okText: 'Aceptar',
            okType: 'primary',
            content: (
                <div>
                    <span>¿Desea {(activar) ? 'activar' : 'desactivar'} el usuario seleccionado?</span>
                </div>
            ),
            onOk() {
                setSpinTitle((activar) ? 'Activando usuarios...' : 'Desactivando usuarios...');
                setSpinning(true);
                Axios.post("mts/activarDesactivarUsser",
                    {
                        idusuario: selectedRowKeys[0],
                        activo: (activar) ? 1 : 0
                    },
                    {
                        headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if (response.data.success) {
                        messageApi.open({
                            type: 'success',
                            content: (activar) ? 'Usuario activado satisfactoriamente' : 'Usuario desactivado satisfactoriamente',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    } else {
                        messageApi.open({
                            type: 'error',
                            content: (activar) ? 'Error activando usuario' : 'Error desactivando usuario',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: (activar) ? 'Error activando usuario' : 'Error desactivando usuario',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);


                    });
            },
        });
    }

    const cambiarPass = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('user_mod', JSON.stringify(row));
    };


    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if (value === "" || value === null) {
            setData(original);
        } else {
            let aux = [];
            original.forEach(function (option) {
                if (option.correo.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };

    const showModalVer = async () => {
        const row = await getInfoForm(selectedRowKeys[0]);
        setSelectedRow(row); // Guardar la fila seleccionada
        setIsVerVisible(true); // Mostrar el modal
    };
    const handleCancelVer = () => {
        setIsVerVisible(false); // Ocultar el modal
    };


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate = async (record) => {
        setSelectedRowKeys([record.key]);
        form.resetFields();
        let row = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].idusuario === record.key) {
                row = data[i];
            }
        }

        sessionStorage.setItem('user_mod', JSON.stringify(row));
        navigate(`/editusuarios/${record.key}`)

    };


    return (

        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                //alignItems: 'center',
                padding: '10px'
            }}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    {t('usser.title')}
                </p>
                {contextHolder}
                <div
                    style={{
                        marginBottom: 2,
                    }}
                >
                    {contextHolder}
                    <Space direction='horizontal'>
                        <Link to="/addusuarios">
                            <Tooltip title={t('usser.add')}>
                                <Button style={{marginRight: "0px", marginLeft: "10px"}} type="primary" shape="circle"
                                        className="button" icon={<IoPersonAdd/>} disabled={loading}>

                                </Button>
                            </Tooltip>
                        </Link>
                        <Link to={`/editusuarios/${selectedRowKeys[0]}`}>
                            <Tooltip title={t('usser.edit')}>
                                <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                        className="button" icon={<AiFillEdit/>} onClick={modificar}
                                        disabled={loading || selectedRowKeys.length !== 1}>

                                </Button>
                            </Tooltip>
                        </Link>
                        <Tooltip title={t('usser.details')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<EyeOutlined/>} onClick={showModalVer}
                                    disabled={!hasSelected || loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>
                        <Tooltip title={t('usser.delete')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle" danger
                                    className="button" icon={<AiFillDelete/>} onClick={eliminar}
                                    disabled={!hasSelected || loading}>

                            </Button>
                        </Tooltip>
                        <Link to={`/cambiarpass/${selectedRowKeys[0]}`}>
                            <Tooltip title={t('usser.change_password')}>
                                <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                        className="button" icon={<FormOutlined/>} onClick={cambiarPass}
                                        disabled={loading || selectedRowKeys.length !== 1}>

                                </Button>
                            </Tooltip>
                        </Link>

                        <Tooltip title={t('usser.activate')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<IoPersonSharp size={15}/>} onClick={(e) => {
                                activacion(e, true);
                            }} disabled={loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>

                        <Tooltip title={t('usser.deactivate')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle" danger
                                    className="button" icon={<IoPersonSharp size={15}/>} onClick={(e) => {
                                activacion(e, false);
                            }} disabled={loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>

                        <Tooltip title={t('global.reload')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<IoReload/>} onClick={recargar}
                                    loading={loading || loading}>

                            </Button>
                        </Tooltip>

                        <Search
                            enterButton
                            placeholder={t('usser.mail')}
                            style={{
                                width: 200,
                            }}
                            size="middle"
                            onSearch={onSearch}
                            value={searchCorreoValue} // El valor del input es controlado por el estado
                            onChange={(e) => {
                                setSearchCorreo(e.target.value); // Actualiza el estado con lo que se escribe
                                filterLocal(e.target.value);
                            }}
                        />
                    </Space>

                    <Modal
                        title={<div style={{textAlign: 'center', width: '100%'}}>{t('usser.general_details')}</div>}
                        open={isVerVisible}
                        onCancel={handleCancelVer}
                        footer={
                            <div style={{textAlign: 'center', width: '100%'}}>
                                <Button type="primary" onClick={handleCancelVer} style={{marginRight: 8}}>
                                    OK
                                </Button>
                            </div>
                        }
                    >
                        {selectedRow && (
                            <div>
                                <p><strong>{t('usser.usser')}:</strong> {selectedRow.nombreusuario}</p>
                                <p><strong>{t('usser.role')}:</strong> {selectedRow.rol}</p>
                                <p><strong>{t('usser.name')}:</strong> {selectedRow.nombre}</p>
                                <p><strong>{t('usser.papell')}:</strong> {selectedRow.papell}</p>
                                <p><strong>{t('usser.sapell')}:</strong> {selectedRow.sapell}</p>
                                <p><strong>{t('usser.passport')}:</strong> {selectedRow.carnet}</p>
                                <p><strong>{t('usser.phone')}:</strong> {selectedRow.telefono}</p>
                                <p><strong>{t('usser.mail')}:</strong> {selectedRow.correo}</p>
                                <p><strong>{t('usser.address')}:</strong> {selectedRow.direccion}</p>
                            </div>
                        )}
                    </Modal>

                </div>

                {/*<Row gutter={[16, 16]}>*/}
                <Row>
                    <Col span={24}>
                        {/*<Col xs={24} sm={24} md={12} lg={12} xl={12}>*/}
                        <Table size="small"
                            // scroll={{
                            //     y: 530,
                            // }}
                            // scroll={{ y: 'calc(100vh - 100px)' }}
                               scroll={{x: 'max-content'}}
                               pagination={{
                                   position: [bottom],
                                   current: pagination.current,
                                   pageSize: pagination.pageSize,
                                   showSizeChanger: true,
                                   pageSizeOptions: ['10', '20', '50', '100'],
                                   showTotal: (total) => `Total ${total} elementos`, // Texto que muestra el total de elementos
                               }}
                            //bordered={true}
                               rowSelection={rowSelection}
                               columns={columns} dataSource={data}
                               onChange={handleTableChange} // Este método maneja el cambio de paginación
                               onRow={(record) => {
                                   return {
                                       onDoubleClick: async () => {
                                           //selectRowUpdate(record);
                                       }
                                   }
                               }}
                               expandable={{
                                   expandedRowRender: (record) => (
                                       <p
                                           style={{
                                               margin: 0,
                                               marginLeft: 117,
                                               color: '#38516D'
                                           }}
                                       >
                                           <b>{t('usser.name')} : </b>
                                           {record.nombre}
                                           <br></br>

                                           <b>{t('usser.papell')} : </b>
                                           {record.papell}
                                           <br></br>

                                           <b>{t('usser.sapell')} : </b>
                                           {record.sapell}
                                           <br></br>

                                           <b>{t('usser.passport')} : </b>
                                           {record.carnet}
                                           <br></br>

                                           <b>{t('usser.address')} : </b>
                                           {record.direccion}

                                       </p>


                                   ),
                                   rowExpandable: (record) => record.direccion !== 'Not Expandable',
                               }}
                        />
                    </Col>
                </Row>

            </div>
        </Spin>
    );
};
export default Usuarios;