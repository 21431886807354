// geocodeService.js
export const geocodeAddress = async (address) => {
    // Si no se proporciona una dirección, devuelve el centro de Cuba
    if (!address || address === "null. null") {
        return {
            lat: 21.521757,
            lng: -77.781167
        };
    }
    try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&limit=1`);
        const data = await response.json();

        if (data.length > 0) {
            return {
                lat: parseFloat(data[0].lat),
                lng: parseFloat(data[0].lon)
            };
        } else {
            // Coordenadas del centro de Cuba
            return {
                lat: 21.521757,
                lng: -77.781167
            };
        }
    } catch (error) {
        console.error("Error al geocodificar la dirección:", error);
        // Coordenadas del centro de Cuba
        return {
            lat: 21.521757,
            lng: -77.781167
        };
    }
};
