import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const PaymentFailed = () => {
    const navigate = useNavigate();

    const handleRetry = () => {
        navigate('/paymetLink');  //link de pago para volver a intentar el pago
    };

  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Result
          status="error"
          title="Pago Fallido"
          subTitle="Hubo un problema al procesar tu pago. Por favor, intenta nuevamente."
          extra={[
            /*<Button type="primary" key="retry" onClick={handleRetry}>
              Intentar de Nuevo
            </Button>,*/
            <Button key="home" onClick={() => navigate('/principal')}>
              Volver al Inicio
            </Button>,
          ]}
        />
      </div>
  );
};

export default PaymentFailed;
