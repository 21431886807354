import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Table,
  message,
  Form,
  Modal,
  Select,
  Spin,
  Space,
  Row,
  Col,
  Tooltip,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { MdAddHome } from "react-icons/md";
import {
  CarOutlined,
  CheckCircleOutlined,
  ShareAltOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { AiFillAlert, AiFillDelete, AiFillEdit } from "react-icons/ai";
import {
  IoPersonSharp,
  IoReload,
  IoCheckmarkCircleSharp,
  IoLockClosedSharp,
  IoLockOpen,
} from "react-icons/io5";
import Search from "antd/es/input/Search";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { getRouteData } from "../servicios/osrmService";

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_APPI_ADDRESS,
});
const { Option } = Select;

const DeliveryAssignment = () => {
  if (sessionStorage.getItem("user_mod")) sessionStorage.removeItem("user_mod");

  const { t, i18n } = useTranslation();
  const [bottom, setBottom] = useState("bottomCenter");
  const [original, setOriginal] = useState([]);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [spinning, setSpinning] = useState(false);
  const [spinTitle, setSpinTitle] = useState(t("global.loading"));
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading] = useState(false);
  const [searchValue, setSearchValue] = useState(""); // Estado para controlar el valor del input
  const [completarCargaButtonDisabled, setCompletarCargaButtonDisabled] =
    useState(true);
  const [habilitarCargaButtonDisabled, setHabilitarCargaButtonDisabled] =
    useState(true);
  const [editarCargaButtonDisabled, setEditarCargaButtonDisabled] =
    useState(true);

  const headers = {
    accept: "*/*",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
    "Content-Type": "application/json",
  };

  useEffect(() => {
    recargar();
  }, []);

  const filterData = (data) => (formatter) =>
    data.map((item) => ({
      text: formatter(item),
      value: formatter(item),
    }));

  const columns = [
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("assignment.col_matricula")}
        </p>
      ),
      dataIndex: "matricula",
      width: 200,
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.matricula),
        _.isEqual
      ),
      onFilter: (value, record) => record.matricula.startsWith(value),
      sorter: (a, b) => a.matricula.localeCompare(b.matricula),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("assignment.col_tipo_vehiculo")}
        </p>
      ),
      dataIndex: "tipo_vehiculo",
      width: 200,
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.tipo_vehiculo),
        _.isEqual
      ),
      onFilter: (value, record) => record.tipo_vehiculo.startsWith(value),
      sorter: (a, b) => a.tipo_vehiculo.localeCompare(b.tipo_vehiculo),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("assignment.col_capacidad_carga")}
        </p>
      ),
      dataIndex: "capacidad_carga",
      width: 200,
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.capacidad_carga),
        _.isEqual
      ),
      onFilter: (value, record) => record.capacidad_carga.startsWith(value),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("assignment.col_capacidad_carga_ocupada")}
        </p>
      ),
      dataIndex: "capacidad_ocupada",
      width: 200,
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.capacidad_ocupada),
        _.isEqual
      ),
      onFilter: (value, record) => record.capacidad_ocupada.startsWith(value),
      render: (text, record) => {
        const isFullCapacity =
          record.capacidad_ocupada > record.capacidad_carga;
        return (
          <span style={{ color: isFullCapacity ? "red" : "inherit" }}>
            {text}
          </span>
        );
      },
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("assignment.col_capacidad_carga_libre")}
        </p>
      ),
      dataIndex: "capacidad_libre",
      width: 200,
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.capacidad_libre),
        _.isEqual
      ),
      onFilter: (value, record) => record.capacidad_libre.startsWith(value),
      render: (text) => {
        const isPositive = Number(text) > 0;
        return (
          <span style={{ color: isPositive ? "green" : "red" }}>{text}</span>
        );
      },
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("assignment.col_chofer")}
        </p>
      ),
      dataIndex: "fullname",
      width: 200,
      filterSearch: true,
      filters: _.uniqWith(
        filterData(data)((i) => i.fullname),
        _.isEqual
      ),
      onFilter: (value, record) => record.fullname.startsWith(value),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("assignment.col_full_charge")}
        </p>
      ),
      dataIndex: "completado",
      // width: 200,
      render: (text) =>
        text === "1" ? (
          <IoCheckmarkCircleSharp size={20} color={"green"} />
        ) : (
          <IoCheckmarkCircleSharp size={20} color={"#adb5bd"} />
        ),
      // filterSearch: true,
      // filters: _.uniqWith(filterData(data)(i => i.activo), _.isEqual),
      // onFilter: (value, record) => record.activo.startsWith( value ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("assignment.col_route")}
        </p>
      ),
      dataIndex: "rutagenerada",
      // width: 200,
      render: (text) =>
        text === "1" ? (
          <IoCheckmarkCircleSharp size={20} color={"green"} />
        ) : (
          <IoCheckmarkCircleSharp size={20} color={"#adb5bd"} />
        ),
      // filterSearch: true,
      // filters: _.uniqWith(filterData(data)(i => i.activo), _.isEqual),
      // onFilter: (value, record) => record.activo.startsWith( value ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px 0",
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("assignment.col_confirmada")}
        </p>
      ),
      dataIndex: "confirmada",
      // width: 200,
      render: (text) =>
        text === "1" ? (
          <IoCheckmarkCircleSharp size={20} color={"green"} />
        ) : (
          <IoCheckmarkCircleSharp size={20} color={"#adb5bd"} />
        ),
      // filterSearch: true,
      // filters: _.uniqWith(filterData(data)(i => i.activo), _.isEqual),
      // onFilter: (value, record) => record.activo.startsWith( value ),
    },
  ];

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: data.length, // Asegúrate de tener el total de elementos aquí
  });

  const handleTableChange = (newPagination) => {
    setPagination({
      ...pagination,
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });
  };

  const recargar = () => {
    //limpiar la busqueda del componente Search
    setSearchValue("");

    setSpinTitle(t("global.loading"));
    setSpinning(true);
    setSelectedRowKeys([]);
    let aux = [];

    let start = 0;
    let limit = 1000;

    Axios.post(
      "sd/getVehiculosCargados",
      {
        // "start": start,
        // "limit": limit,
        // "filtro":{
        //     "idrol": 1
        // }
      },
      {
        headers: headers,
      }
    )
      .then((response) => {
        setSpinning(false);
        if (response.data.data.length > 0) {
          for (let i = 0; i < response.data.data.length; i++) {
            aux.push({
              key: response.data.data[i].idvehiculo,
              idasignacion: response.data.data[i].idasignacion,
              idvehiculo: response.data.data[i].idvehiculo,
              matricula: response.data.data[i].matricula,
              capacidad_carga: response.data.data[i].capacidad_carga,
              capacidad_ocupada: response.data.data[i].capacidad_ocupada,
              capacidad_libre: response.data.data[i].capacidad_libre,
              fullname: response.data.data[i].fullname,
              tipo_vehiculo: response.data.data[i].tipo_vehiculo,
              completado: response.data.data[i].completado,
              rutagenerada: response.data.data[i].rutagenerada,
              confirmada: response.data.data[i].confirmada,
            });
          }
        }

        setData(aux);
        setOriginal(aux);
      })
      .catch((error) => {
        setSpinning(false);
      });
  };

  const getInfoForm = async (id) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].idvehiculo === id) {
        return data[i];
      }
    }
    return [];
  };

  const modificar = async () => {
    form.resetFields();
    const row = await getInfoForm(selectedRowKeys[0]);
    // console.log(row);
    sessionStorage.setItem("sessionStorage", JSON.stringify(row));
  };

  const eliminar = (e) => {
    Modal.confirm({
      title: "Eliminar datos!",
      cancelText: "Cancelar",
      okText: "Aceptar",
      okType: "primary",
      content: (
        <div>
          <span>{t("assignment.delete_data")}</span>
        </div>
      ),
      onOk() {
        setSpinTitle(t("global.processing_data"));
        setSpinning(true);
        Axios.delete("sd/deleteAsignacion", {
          data: {
            idvehiculo: selectedRowKeys,
          },
          headers: headers,
        })
          .then((response) => {
            setSpinning(false);
            if (response.data.success) {
              messageApi.open({
                type: "success",
                content: response.data.message,
                duration: 0,
              });

              setTimeout(messageApi.destroy, 2500);
              recargar();
            } else {
              messageApi.open({
                type: "error",
                content: t("info.error"),
                duration: 0,
              });

              setTimeout(messageApi.destroy, 2500);
            }
          })
          .catch((error) => {
            setSpinning(false);
            messageApi.open({
              type: "error",
              content: t("info.error"),
              duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);
          });
      },
    });
  };

  const onSearch = (value, _e, info) => {
    filterLocal(value);
  };

  const filterLocal = (value) => {
    if (value === "" || value === null) {
      setData(original);
    } else {
      let aux = [];
      original.forEach(function (option) {
        if (
          option.matricula.toUpperCase().indexOf(value.toUpperCase()) !== -1
        ) {
          aux.push(option);
        }
      });
      setData(aux);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (newSelectedRowKeys.length === 1) {
      const selectedRow = data.find((row) => row.key === newSelectedRowKeys[0]);
      if (selectedRow) {
        setCompletarCargaButtonDisabled(selectedRow.completado == 1);
        setHabilitarCargaButtonDisabled(selectedRow.completado == 0);
        setEditarCargaButtonDisabled(selectedRow.completado == 1);
      }
    } else {
      setCompletarCargaButtonDisabled(true);
      setHabilitarCargaButtonDisabled(true);
      setEditarCargaButtonDisabled(true);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const selectRowUpdate = async (record) => {
    setSelectedRowKeys([record.key]);
    form.resetFields();
    let row = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].idvehiculo === record.key) {
        row = data[i];
      }
    }

    sessionStorage.setItem("user_mod", JSON.stringify(row));
    navigate(`/editTipoVehiculo/${record.key}`);
  };

  function activacion(e, activar) {
    Modal.confirm({
      title: activar ? "Completar carga" : "Habilitar carga",
      cancelText: "Cancelar",
      okText: "Aceptar",
      okType: "primary",
      content: (
        <div>
          <span>
            ¿Desea {activar ? "completar" : "habilitar"} la carga del vehículo
            seleccionado?
          </span>
        </div>
      ),
      onOk() {
        setSpinTitle(
          activar
            ? "Finlizando carga del vehículo..."
            : "Habilitando carga del vehículo..."
        );
        setSpinning(true);
        Axios.post(
          "sd/completarCargaVehiculo",
          {
            idvehiculo: selectedRowKeys,
            completado: activar ? 1 : 0,
          },
          {
            headers: headers,
          }
        )
          .then((response) => {
            setSpinning(false);
            if (response.data.success) {
              messageApi.open({
                type: "success",
                content: "Los datos han sido procesados con éxito.",
                duration: 0,
              });
              setTimeout(messageApi.destroy, 2500);
              recargar();
            } else {
              messageApi.open({
                type: "error",
                content: "Error procesando los datos.",
                duration: 0,
              });

              setTimeout(messageApi.destroy, 2500);
            }
          })
          .catch((error) => {
            setSpinning(false);
            messageApi.open({
              type: "error",
              content: "Error procesando los datos.",
              duration: 0,
            });
            setTimeout(messageApi.destroy, 2500);
          });
      },
    });
  }

  function generarRuta() {
    Modal.confirm({
      title: "Generar ruta",
      cancelText: "Cancelar",
      okText: "Aceptar",
      okType: "primary",
      content: (
        <div>
          <span>¿Desea generar la ruta para el vehículo seleccionado?</span>
        </div>
      ),
      onOk() {
        setSpinTitle("Generando la ruta...");
        setSpinning(true);
        Axios.post(
          "sd/generarRuta",
          {
            idvehiculo: selectedRowKeys,
          },
          {
            headers: headers,
          }
        )
          .then((response) => {
            setSpinning(false);
            if (response.data.success) {
              // console.log(response.data.data[0]);

              let coordinatesArray = JSON.parse(response.data.data[0].ruta);
              //console.log(coordinatesArray);

              // Validar que no haya subarrays con valores null
              if (
                coordinatesArray.some((coordinate) => coordinate.includes(null))
              ) {
                messageApi.open({
                  type: "error",
                  content:
                    "El array de coordenadas contiene valores nulos. No se puede continuar con la generación de la ruta.",
                  duration: 0,
                });
                setTimeout(messageApi.destroy, 2500);
                return; // Detener ejecución si hay valores null
              }

              getRouteData(coordinatesArray)
                .then(({ distance, duration, geometry }) => {
                  // console.log(`Distancia: ${(distance / 1000).toFixed(2)} km`);
                  // console.log(
                  //   `Duración: ${(duration / 3600).toFixed(2)} horas`
                  // );
                  // console.log("Ruta GeoJSON:", geometry);

                  Axios.post(
                    "sd/updateValoresRuta",
                    {
                      idruta: response.data.data[0].idruta,
                      distanciatotal: `${(distance / 1000).toFixed(2)} km`,
                      tiempoestimado: `${(duration / 3600).toFixed(2)} horas`,
                    },
                    {
                      headers: headers,
                    }
                  );
                })
                .catch((error) => console.error("Error:", error));

              messageApi.open({
                type: "success",
                content: "Los datos han sido procesados con éxito.",
                duration: 0,
              });
              setTimeout(messageApi.destroy, 2500);
              recargar();
            } else {
              messageApi.open({
                type: "error",
                content: "Error procesando los datos.",
                duration: 0,
              });

              setTimeout(messageApi.destroy, 2500);
            }
          })
          .catch((error) => {
            setSpinning(false);
            messageApi.open({
              type: "error",
              content: "Error procesando los datos.",
              duration: 0,
            });
            setTimeout(messageApi.destroy, 2500);
          });
      },
    });
  }

  function confirmar(e, activar) {
    Modal.confirm({
      title: activar ? "Confirmar ruta" : "Desconfirmar ruta",
      cancelText: "Cancelar",
      okText: "Aceptar",
      okType: "primary",
      content: (
        <div>
          <span>
            ¿Desea {activar ? "confirmar" : "desconfirmar"} la ruta de este
            vehículo?
          </span>
        </div>
      ),
      onOk() {
        setSpinTitle(t("global.processing_data"));
        setSpinning(true);
        let message = "";
        Axios.post(
          "sd/confirmarRuta",
          {
            idvehiculo: selectedRowKeys[0],
            confirmada: activar ? 1 : 0,
          },
          {
            headers: headers,
          }
        )
          .then((response) => {
            setSpinning(false);
            if (response.data.success) {
              message = response.data.message;
              messageApi.open({
                type: "success",
                content: message,
                duration: 0,
              });

              setTimeout(messageApi.destroy, 2500);
              recargar();
            } else {
              messageApi.open({
                type: "error",
                content: message,
                duration: 0,
              });

              setTimeout(messageApi.destroy, 2500);
            }
          })
          .catch((error) => {
            setSpinning(false);
            messageApi.open({
              type: "error",
              content: message,
              duration: 0,
            });
            setTimeout(messageApi.destroy, 2500);
          });
      },
    });
  }

  return (
    <Spin spinning={spinning} tip={spinTitle} size={"large"}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          //alignItems: 'center',
          padding: "10px",
        }}
      >
        <p
          style={{
            textAlign: "center",
            margin: "10px 0",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#2c3e50",
            fontFamily: "'Roboto', sans-serif",
            borderBottom: "2px solid #61768B",
            paddingBottom: "5px",
            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {t("assignment.title")}
        </p>
        {contextHolder}
        <div
          style={{
            marginBottom: 10,
          }}
        >
          {contextHolder}
          <Space direction="horizontal">
            <Link to="/vehicleassignment">
              <Tooltip title={t("assignment.add")}>
                <Button
                  style={{ marginRight: "0px", marginLeft: "10px" }}
                  type="primary"
                  shape="circle"
                  className="button"
                  icon={<PlusOutlined />}
                  disabled={loading}
                ></Button>
              </Tooltip>
            </Link>
            <Link to={`/editvehicleassignment/${selectedRowKeys[0]}`}>
              <Tooltip title={t("assignment.edit")}>
                <Button
                  style={{ marginRight: "0px", marginLeft: "0px" }}
                  type="primary"
                  shape="circle"
                  className="button"
                  icon={<AiFillEdit />}
                  onClick={modificar}
                  disabled={
                    editarCargaButtonDisabled || selectedRowKeys.length !== 1
                  }
                ></Button>
              </Tooltip>
            </Link>
            <Tooltip title={t("assignment.completar_carga")}>
              <Button
                style={{ marginRight: "0px", marginLeft: "0px" }}
                type="primary"
                shape="circle"
                className="button"
                icon={<IoLockClosedSharp size={15} />}
                onClick={(e) => {
                  activacion(e, true);
                }}
                disabled={
                  completarCargaButtonDisabled || selectedRowKeys.length !== 1
                }
              ></Button>
            </Tooltip>

            <Tooltip title={t("assignment.habilitar_carga")}>
              <Button
                style={{ marginRight: "0px", marginLeft: "0px" }}
                type="primary"
                shape="circle"
                className="button"
                icon={<IoLockOpen size={15} />}
                onClick={(e) => {
                  activacion(e, false);
                }}
                disabled={
                  habilitarCargaButtonDisabled || selectedRowKeys.length !== 1
                }
              ></Button>
            </Tooltip>

            <Tooltip title={t("assignment.route")}>
              <Button
                style={{ marginRight: "0px", marginLeft: "0px" }}
                type="primary"
                shape="circle"
                className="button"
                icon={<ShareAltOutlined size={15} />}
                onClick={generarRuta}
                disabled={loading || selectedRowKeys.length !== 1}
              ></Button>
            </Tooltip>

            <Tooltip title={t("assignment.confirmar")}>
              <Button
                style={{ marginRight: "0px", marginLeft: "0px" }}
                type="primary"
                shape="circle"
                className="button"
                icon={<CheckCircleOutlined size={15} />}
                onClick={(e) => {
                  confirmar(e, true);
                }}
                disabled={loading || selectedRowKeys.length !== 1}
              ></Button>
            </Tooltip>

            <Tooltip title={t("assignment.desconfirmar")}>
              <Button
                style={{ marginRight: "0px", marginLeft: "0px" }}
                type="primary"
                danger
                shape="circle"
                className="button"
                icon={<CheckCircleOutlined size={15} />}
                onClick={(e) => {
                  confirmar(e, false);
                }}
                disabled={loading || selectedRowKeys.length !== 1}
              ></Button>
            </Tooltip>

            <Tooltip title={t("assignment.delete")}>
              <Button
                style={{ marginRight: "0px", marginLeft: "0px" }}
                type="primary"
                shape="circle"
                danger
                className="button"
                icon={<AiFillDelete />}
                onClick={eliminar}
                disabled={!hasSelected || loading}
              ></Button>
            </Tooltip>

            <Tooltip title={t("assignment.update")}>
              <Button
                style={{ marginRight: "0px", marginLeft: "0px" }}
                type="primary"
                shape="circle"
                className="button"
                icon={<IoReload />}
                onClick={recargar}
                loading={loading || loading}
              ></Button>
            </Tooltip>

            <Search
              placeholder={t("assignment.search")}
              enterButton
              style={{
                width: 230,
              }}
              size="middle"
              onSearch={onSearch}
              value={searchValue} // El valor del input es controlado por el estado
              onChange={(e) => {
                setSearchValue(e.target.value); // Actualiza el estado con lo que se escribe
                filterLocal(e.target.value);
              }}
            />
          </Space>
        </div>

        <Row>
          <Col span={24}>
            <Table
              size="small"
              // scroll={{
              //     y: 350,
              // }}
              bordered={true}
              pagination={{
                position: [bottom],
                current: pagination.current,
                pageSize: pagination.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total) => `Total ${total} elementos`, // Texto que muestra el total de elementos
              }}
              rowSelection={rowSelection}
              onChange={handleTableChange}
              columns={columns}
              dataSource={data}
              onRow={(record) => {
                return {
                  onDoubleClick: async () => {
                    // selectRowUpdate(record);
                  },
                };
              }}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};
export default DeliveryAssignment;
