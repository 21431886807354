import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Table, Input, Button, Form, DatePicker, Modal, Space, Tooltip, Spin, message, Row, Col} from 'antd';
import moment from 'moment';
import {FormattedMessage} from "react-intl";
// import {SearchOutlined} from "react-icons/io5";
import {
    CloseOutlined,
    ImportOutlined,
    SearchOutlined,
    CloudDownloadOutlined,
    EnvironmentOutlined,
    CheckCircleOutlined,
    SaveOutlined, ArrowLeftOutlined
} from '@ant-design/icons';
import {IoCheckmarkCircleSharp, IoReload, IoLocation, IoLocationOutline, IoLocationSharp  } from "react-icons/io5";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import InvoiceDetailsModal from '../facturas/InvoiceDetailsModal';
import GlobalSelect from "../../generales/GlobalSelect";
import {Link} from "react-router-dom";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

function VehicleAssignment() {

    const {t, i18n} = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [form] = Form.useForm();
    const [formBuscarFactura] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal
    const [isModalBuscarFacturaOpen, setIsModalBuscarFacturaOpen] = useState(false); // Estado para controlar el modal
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState(t('global.loading'));
    const [bottom, setBottom] = useState('bottomCenter');
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalOpenDetalles, setIsModalOpenDetalles] = useState(false); // Estado para controlar el modal
    const [selectedInvoice, setSelectedInvoice] = useState(null); // Almacenar la factura seleccionada
    const [vehiculo, setVehiculo] = useState([]);
    const [dataRecord, setDataRecord] = useState([]);


    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        recargar();
        cargarVehiculosDisponibles();
    }, []);

    const cargarVehiculosDisponibles = () => {
        setSpinning(true);
        let aux = [];
        // let start = 0;
        // let limit = 1000;

        Axios.post("sd/getVehiculosDisponibles",
            {
                // "start": start,
                // "limit": limit,
                // "filtros": {
                //     "idrol": 4
                // }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        id: response.data.data[i].idvehiculo,
                        // matricula:response.data.data[i].matricula,
                        // vehiculo:response.data.data[i].vehiculo,
                        // tipo_vehiculo:response.data.data[i].tipo_vehiculo,
                        nombre: response.data.data[i].vehiculo
                    });
                }
            }
            setVehiculo(aux);
        }).catch(error => {
            setSpinning(false);
            setVehiculo(aux);
        })
    };

    const recargar = () => {

        setSpinTitle(t('global.loading'));
        setSpinning(true);

        resetSelect();
        handleDeselectAll();

        // setSelectedRowKeys([]);
        let aux = [];

        // let start = 0;
        // let limit = 1000;

        Axios.post("sd/getFacturas",
            {
                // "start": start,
                // "limit": limit,
                "filtros": [{
                    "asignada": 0
                }]
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);

            // Asumiendo que response.data.data es un array
            const rawData = response.data.data;

            // No necesitas usar Object.keys, solo asigna rawData directamente
            const formattedData = rawData.map(item => ({
                code: item.code,
                idinvoice: item.idinvoice,
                rdatereserve: item.rdatereserve,
                cname: item.cname,
                cidentity: item.cidentity,
                address: item.address,
                cprovince: item.cprovince,
                cmunicipality: item.cmunicipality,
                sname: item.sname,
                idestadoinvoice: item.idestadoinvoice,
                estadoinvoice: item.estadoinvoice,
                prioridad: item.prioridad,
                latitude: item.latitude,
                longitude: item.longitude,
                coordinates: item.coordinates,
                products: JSON.parse(item.products)  // Asegúrate de que el valor sea un array
            }));

            // console.log(formattedData);
            setData(formattedData);

        }).catch(error => {
            setSpinning(false);
        });

    };
    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const showModalVerDetalles = (record) => {
        setSelectedInvoice(record); // Guardar la factura seleccionada
        setIsModalOpenDetalles(true); // Mostrar el modal
    };

    const handleCancelVerDetalles = () => {
        setIsModalOpenDetalles(false); // Cerrar el modal
        setSelectedInvoice(null); // Limpiar la factura seleccionada
    };

    const columns = [
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_identificador')}</p>,
            dataIndex: 'code',
            key: 'code',
            width: 80,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.code), _.isEqual),
            onFilter: (value, record) => record.code.startsWith(value),
            sorter: (a, b) => a.code.localeCompare(b.code),
            render: (text, record) => (
                <Button type="link" onClick={() => showModalVerDetalles(record)}>
                    {text}
                </Button>
            )
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_fecha_reserva')}</p>,
            dataIndex: 'rdatereserve',
            key: 'rdatereserve',
            width: 150,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.rdatereserve), _.isEqual),
            onFilter: (value, record) => record.rdatereserve.startsWith(value),
            sorter: (a, b) => a.rdatereserve.localeCompare(b.rdatereserve)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_destinatario')}</p>,
            dataIndex: 'cname',
            key: 'cname',
            width: 300,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cname), _.isEqual),
            onFilter: (value, record) => record.cname.startsWith(value),
            sorter: (a, b) => a.cname.localeCompare(b.cname)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_identidad')}</p>,
            dataIndex: 'cidentity',
            key: 'cidentity',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cidentity), _.isEqual),
            onFilter: (value, record) => record.cidentity.startsWith(value),
            sorter: (a, b) => a.cidentity.localeCompare(b.cidentity)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_direccion')}</p>,
            dataIndex: 'address',
            key: 'address',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.address), _.isEqual),
            onFilter: (value, record) => record.address.startsWith(value),
            sorter: (a, b) => a.address.localeCompare(b.address)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_municipio')}</p>,
            dataIndex: 'cmunicipality',
            key: 'cmunicipality',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cmunicipality), _.isEqual),
            onFilter: (value, record) => record.cmunicipality.startsWith(value),
            sorter: (a, b) => a.cmunicipality.localeCompare(b.cmunicipality)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_provincia')}</p>,
            dataIndex: 'cprovince',
            key: 'cprovince',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cprovince), _.isEqual),
            onFilter: (value, record) => record.cprovince.startsWith(value),
            sorter: (a, b) => a.cprovince.localeCompare(b.cprovince)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_remitente')}</p>,
            dataIndex: 'sname',
            key: 'sname',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.sname), _.isEqual),
            onFilter: (value, record) => record.sname.startsWith(value),
            sorter: (a, b) => a.sname.localeCompare(b.sname)
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_stateinvoice')}</p>,
            dataIndex: 'estadoinvoice',
            key: 'estadoinvoice',
            filterSearch: true,
            width: 150,
            filters: _.uniqWith(filterData(data)(i => i.estadoinvoice), _.isEqual),
            onFilter: (value, record) => record.estadoinvoice.startsWith(value),
            sorter: (a, b) => a.estadoinvoice.localeCompare(b.estadoinvoice)
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_priority')}</p>,
            dataIndex: 'prioridad',
            key: 'prioridad',
            filterSearch: true,
            width: 150,
            filters: _.uniqWith(filterData(data)(i => i.prioridad), _.isEqual),
            onFilter: (value, record) => record.prioridad.startsWith(value),
            sorter: (a, b) => String(a.prioridad).localeCompare(String(b.prioridad))
        }
    ];

    const productColumns = [
        {
            title: t('invoice.col_nombre_producto'),
            dataIndex: 'namegood',
            key: 'namegood',
        },
        {
            title: t('invoice.col_cantidad'),
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: t('invoice.col_peso'),
            dataIndex: 'weight',
            key: 'weight',
        },{
            title: t('invoice.col_longitud'),
            dataIndex: 'length',
            key: 'length',
        },{
            title: t('invoice.col_ancho'),
            dataIndex: 'width',
            key: 'width',
        },{
            title: t('invoice.col_alto'),
            dataIndex: 'height',
            key: 'height',
        },
        {
            title: t('invoice.col_dimensiones'),
            render: (text, record) => `${record.length} x ${record.width} x ${record.height} (cm)`,
            key: 'dimensions',
        },
    ];

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: data.length, // Asegúrate de tener el total de elementos aquí
    });

    const handleTableChange = (newPagination) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    const expandedRowRender = (record) => {
        return <Table columns={productColumns} dataSource={record.products} pagination={false} rowKey="namegood" />;
    };

    //
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);

        if (newSelectedRowKeys.length === 1) {
            const selectedRow = data.find(row => row.code === newSelectedRowKeys[0]);
            // console.log("Selected Row: ", selectedRow);
            if (selectedRow) {
                // setDataRecord(selectedRow.cmunicipality + '. ' + selectedRow.cprovince);
                setDataRecord(selectedRow);
                // console.log(dataRecord);
            }
        }

    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleDeselectAll = () => {
        setSelectedRowKeys([]); // Esto desmarca todas las filas
    };
    const [selectedVehiculo, setSelectedVehiculo] = useState(null);
    const handleSelectChange = (value) => {
        console.log("Vehículo seleccionado:", value);
        setSelectedVehiculo(value); // Almacena el valor seleccionado
    };
    // Resetear y limpiar el campo
    const resetSelect = () => {
        setSelectedVehiculo(null); // Reinicia el valor en el estado
        form.resetFields(['idvehiculo']); // Limpia el campo en el formulario
    };

    const asignarPaquetes = () => {

        setSpinTitle(t('global.loading'));
        setSpinning(true);

        Axios.post("sd/asignarPaqueteVehiculo",
            {
                "idvehiculo": selectedVehiculo,
                "idinvoice": selectedRowKeys
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            recargar();
            cargarVehiculosDisponibles();

        }).catch(error => {
            setSpinning(false);
        });

    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                //alignItems: 'center',
                padding: '10px'
            }}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    {t('assignment.subtitle')}
                </p>

                {contextHolder}

                <Form form={form} layout="vertical">
                    <Space direction='horizontal'>
                        <Link to="/assignment">
                            <Tooltip title={t('assignment.back')}>
                                <Button style={{marginRight: "0px", marginLeft: "10px"}} type="primary" shape="circle"
                                        className="button" icon={<ArrowLeftOutlined/>} disabled={loading}>

                                </Button>
                            </Tooltip>
                        </Link>

                        <label style={{
                            textAlign: "center",
                            margin: "10px 0",
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#2c3e50",
                            fontFamily: "'Roboto', sans-serif",
                            // paddingBottom: "5px",
                            textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                        }}>|</label>

                        <label style={{
                            textAlign: "center",
                            margin: "10px 0",
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#2c3e50",
                            fontFamily: "'Roboto', sans-serif",
                            // paddingBottom: "5px",
                            textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                        }}>{t('assignment.select')}</label>

                        <Form.Item
                            name="idvehiculo"
                            style={{
                                margin: "10px 0",
                            }}
                            rules={[{required: true, message: 'Seleccione un vehículo'}]}
                        >
                            <GlobalSelect
                                disabled={false}
                                loading={spinning}
                                valueName={'idvehiculo'}
                                data={vehiculo}
                                onChange={handleSelectChange} // Controla el cambio
                            />
                        </Form.Item>

                        <Tooltip title={t('assignment.assign')}>
                            <Button style={{
                                marginRight: "0px",
                                marginLeft: "0px",
                                // backgroundColor: !loading && selectedRowKeys.length === 1 ? "green" : "transparent"
                            }}
                                    type="primary" shape="circle" className="button" icon={<SaveOutlined/>}
                                    onClick={asignarPaquetes} disabled={!hasSelected || loading}>

                            </Button>
                        </Tooltip>

                        <Tooltip title={t('invoice.update')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<IoReload/>} onClick={recargar} loading={loading}>

                            </Button>
                        </Tooltip>
                    </Space>
                </Form>

                {/*detalles de la factura*/}

                <InvoiceDetailsModal
                    isOpen={isModalOpenDetalles}
                    onCancel={handleCancelVerDetalles}
                    selectedInvoice={selectedInvoice}
                    productColumns={productColumns}
                />

                {error && <div>Error: {error}</div>}
                <Table
                    dataSource={data}
                    columns={columns}
                    rowKey="idinvoice"
                    bordered={true}
                    expandable={{expandedRowRender}}
                    rowSelection={rowSelection}
                    onChange={handleTableChange}
                    pagination={{
                        position: [bottom],
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '40', '60', '80', '100'],
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal: (total) => `Total ${total} elementos`, // Texto que muestra el total de elementos
                    }}
                />
            </div>
        </Spin>
    );
}

export default VehicleAssignment;
