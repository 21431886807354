import React, { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import axios from 'axios';

const { Option } = Select;

const GlobalSelect = ({ value, onChange }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchStatusOptions = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://www.solved1.com/api/appi/playa-dev/deliverstatelist/', {
                    params: {
                        apikey: '6I77Z9V01XV7IE3U5BM7XQ4X3D3PNK',
                        data: JSON.stringify({ enterprise: 'etcexpress' }),
                    },
                });
                const { data } = response;

                // Ordena los datos por `status.id` de menor a mayor
                const sortedStatusOptions = data
                    .sort((a, b) => a.id - b.id) // Ordena por `status.id`
                    .map((status) => ({
                        label: status.name.replace('CAMAGÃœEY', 'CAMAGUEY'),  // Reemplaza CAMAGÃœEY por CAMAGUEY
                        value: status.id,
                    }));

                setOptions(sortedStatusOptions);
            } catch (error) {
                console.error('Error fetching status options:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchStatusOptions();
    }, []);

    return (
        <Select
            showSearch
            value={value}
            onChange={onChange}
            placeholder="Selecciona un estado"
            loading={loading}
            allowClear
            filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase()) // Filtra por el texto ingresado
            }
            style={{ width: '100%' }}
        >
            {options.map(option => (
                <Option key={option.value} value={option.value}>
                    {option.label}
                </Option>
            ))}
        </Select>
    );
};

export default GlobalSelect;
