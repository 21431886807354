import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Select, Spin, Space, Row, Col, Tooltip} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {IoReload} from "react-icons/io5";
import Search from "antd/es/input/Search";
import {useTranslation} from "react-i18next";
import _ from "lodash";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});
const { Option } = Select;


const AuditoriaRuta = () => {

    if(sessionStorage.getItem('user_mod'))
       sessionStorage.removeItem('user_mod');

    const { t, i18n } = useTranslation();
    const [bottom, setBottom] = useState('bottomCenter');
    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState(t('global.loading'));
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading] = useState(false);
    const [searchValue, setSearchValue] = useState(''); // Estado para controlar el valor del input

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    useEffect(() => {
        recargar();
    }, []);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('auditoria_ruta.col_matricula')}</p>,
            dataIndex: 'matricula',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.matricula), _.isEqual),
            onFilter: (value, record) => record.matricula.startsWith(value),
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('auditoria_ruta.col_marca')}</p>,
            dataIndex: 'marca',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.marca), _.isEqual),
            onFilter: (value, record) => record.marca.startsWith(value),
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('auditoria_ruta.col_modelo')}</p>,
            dataIndex: 'modelo',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.modelo), _.isEqual),
            onFilter: (value, record) => record.modelo.startsWith(value),
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('auditoria_ruta.col_fechaeliminacion')}</p>,
            dataIndex: 'fechaeliminacion',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.fechaeliminacion), _.isEqual),
            onFilter: (value, record) => record.fechaeliminacion.startsWith(value),
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('auditoria_ruta.col_nombreusuario')}</p>,
            dataIndex: 'nombreusuario',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.nombreusuario), _.isEqual),
            onFilter: (value, record) => record.nombreusuario.startsWith(value),
        }
    ];

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: data.length, // Asegúrate de tener el total de elementos aquí
    });

    const handleTableChange = (newPagination) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    const recargar = () => {
        //limpiar la busqueda del componente Search
        setSearchValue('');

        setSpinTitle(t('global.loading'));
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        // let start = 0;
        // let limit = 1000;

        Axios.post("auditoria/getHistorialRuta",
            {
                // "start": start,
                // "limit": limit,
                // "filtro":{
                //     "idrol": 1
                // }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        key: response.data.data[i].idrutahistorial,
                        idrutahistorial: response.data.data[i].idrutahistorial,
                        idvehiculo: response.data.data[i].idvehiculo,
                        matricula: response.data.data[i].matricula,
                        cantidadpaquetes: response.data.data[i].cantidadpaquetes,
                        distanciatotal: response.data.data[i].distanciatotal,
                        tiempoestimado: response.data.data[i].tiempoestimado,
                        fechacancelada: response.data.data[i].fechacancelada,
                        tipovehiculo: response.data.data[i].tipovehiculo,
                        chofer: response.data.data[i].chofer,

                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        }).catch(error => {
            setSpinning(false);
        })
    };

    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.matricula.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                //alignItems: 'center',
                padding: '10px'
            }}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    {t('auditoria_ruta.title')}
                </p>
                {contextHolder}
                <div
                    style={{
                        marginBottom: 10,
                    }}
                >
                    {contextHolder}
                    <Space direction='horizontal'>

                        <Search
                            placeholder={t('auditoria_ruta.search')}
                            enterButton
                            style={{
                                width: 230,
                                marginRight: "0px",
                                marginLeft: "10px"
                            }}
                            size="middle"
                            onSearch={onSearch}
                            value={searchValue} // El valor del input es controlado por el estado
                            onChange={(e) => {
                                setSearchValue(e.target.value); // Actualiza el estado con lo que se escribe
                                filterLocal(e.target.value);
                            }}
                        />

                        <Tooltip title={t('global.reload')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<IoReload/>} onClick={recargar}
                                    loading={loading || loading}>

                            </Button>
                        </Tooltip>
                    </Space>
                </div>

                <Row>
                    <Col span={24}>
                        <Table size="small"
                            // scroll={{
                            //     y: 350,
                            // }}
                               bordered={true}
                               pagination={{
                                   position: [bottom],
                                   current: pagination.current,
                                   pageSize: pagination.pageSize,
                                   showSizeChanger: true,
                                   pageSizeOptions: ['10', '20', '50', '100'],
                                   showTotal: (total) => `Total ${total} elementos`, // Texto que muestra el total de elementos
                               }}
                               // rowSelection={rowSelection}
                               columns={columns} dataSource={data}
                               onRow={(record) => {
                                   return {
                                       onDoubleClick: async () => {
                                           // selectRowUpdate(record);
                                       }
                                   }
                               }}/>
                    </Col>
                </Row>


            </div>
        </Spin>
    );
};
export default AuditoriaRuta;