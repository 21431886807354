import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polyline, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Modal } from 'antd';
import axios from 'axios';
import L from 'leaflet';

// Función para organizar las coordenadas usando el método vecino más cercano
const nearestNeighbor = (coordinates) => {
    if (coordinates.length < 2) return coordinates;

    const visited = new Set();
    const orderedCoordinates = [];
    let currentIndex = 0;

    while (orderedCoordinates.length < coordinates.length) {
        const currentPoint = coordinates[currentIndex];
        orderedCoordinates.push(currentPoint);
        visited.add(currentIndex);

        let closestIndex = -1;
        let shortestDistance = Infinity;

        // Buscar el nodo más cercano no visitado
        coordinates.forEach((point, index) => {
            if (!visited.has(index)) {
                const distance = calculateDistance(currentPoint, point);
                if (distance < shortestDistance) {
                    shortestDistance = distance;
                    closestIndex = index;
                }
            }
        });

        // Avanzar al nodo más cercano
        currentIndex = closestIndex;
    }

    return orderedCoordinates;
};


// Función para calcular la distancia entre dos puntos
// const calculateDistance = ([lat1, lng1], [lat2, lng2]) => {
//     const toRad = (value) => (value * Math.PI) / 180;
//     const R = 6371; // Radio de la Tierra en km
//     const dLat = toRad(lat2 - lat1);
//     const dLng = toRad(lng2 - lng1);
//     const a =
//         Math.sin(dLat / 2) ** 2 +
//         Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLng / 2) ** 2;
//     return 2 * R * Math.asin(Math.sqrt(a));
// };
const calculateDistance = (pointA, pointB) => {
    if (!Array.isArray(pointA) || !Array.isArray(pointB) || pointA.length !== 2 || pointB.length !== 2) {
        throw new Error('Las coordenadas deben ser arreglos de longitud 2 con formato [lat, lng].');
    }

    const [lat1, lng1] = pointA;
    const [lat2, lng2] = pointB;

    // Validar que las coordenadas sean números
    if (![lat1, lng1, lat2, lng2].every((coord) => typeof coord === 'number')) {
        throw new Error('Todas las coordenadas deben ser números.');
    }

    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Radio de la Tierra en km
    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1);
    const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLng / 2) ** 2;
    const distance = 2 * R * Math.asin(Math.sqrt(a));
    return distance;
};


// Componente para centrar el mapa en la ruta
const FitMapToRoute = ({ route }) => {
    const map = useMap();

    useEffect(() => {
        if (route.length > 0) {
            const bounds = L.latLngBounds(route);
            map.fitBounds(bounds, { padding: [50, 50] });
        }
    }, [map, route]);

    return null;
};

const UpdateMapOnModalOpen = () => {
    const map = useMap();
    useEffect(() => {
        map.invalidateSize();
    }, [map]);
    return null;
};

const MapModal = ({ isVisible, onClose, coordinates = [] }) => {
    const [route, setRoute] = useState([]);
    const [distance, setDistance] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRoute = async () => {
            // Asegúrate de que 'coordinates' sea un arreglo válido
            if (!Array.isArray(coordinates) || coordinates.length < 2) {
                console.warn('Se necesitan al menos dos puntos para trazar una ruta.');
                setRoute([]);
                setDistance(0);
                setLoading(false);
                return;
            }

            try {
                const orderedCoordinates = nearestNeighbor(coordinates);
                const coordString = orderedCoordinates
                    .map(([lat, lng]) => `${lng},${lat}`)
                    .join(';');
                const url = `https://router.project-osrm.org/route/v1/driving/${coordString}?overview=full&geometries=geojson`;

                const response = await axios.get(url);
                if (response.data.routes && response.data.routes.length > 0) {
                    const { coordinates: osrmCoordinates } = response.data.routes[0].geometry;
                    const transformedCoordinates = osrmCoordinates.map(([lng, lat]) => [lat, lng]);
                    setRoute(transformedCoordinates);

                    // Calcular la distancia total
                    const totalDistance = transformedCoordinates.reduce((sum, point, index) => {
                        if (index === 0) return sum;
                        return sum + calculateDistance(transformedCoordinates[index - 1], point);
                    }, 0);
                    setDistance(totalDistance.toFixed(2)); // Redondear a 2 decimales
                } else {
                    console.error('No se encontraron rutas en la respuesta de OSRM:', response.data);
                    setRoute([]);
                    setDistance(0);
                }
            } catch (error) {
                console.error('Error al obtener la ruta de OSRM:', error);
                setRoute([]);
                setDistance(0);
            } finally {
                setLoading(false);
            }
        };

        fetchRoute();
    }, [coordinates]);

    const customIcon = new L.Icon({
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    return (
        <Modal
            title={<div style={{ textAlign: 'center' }}>Mapa de Ruta</div>}
            open={isVisible}
            onCancel={onClose}
            footer={null}
            width="90%"
            style={{ top: 20 }}
            styles={{ padding: 0 }}
            destroyOnClose
        >
            <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '16px' }}>
                {loading ? 'Cargando ruta...' : `Distancia Total: ${distance} km`}
            </div>
            <div style={{ height: '610px', width: '100%' }}>
                {loading ? (
                    <p>Cargando ruta...</p>
                ) : (
                    <MapContainer
                        center={[0, 0]}
                        zoom={10}
                        style={{ height: '100%', width: '100%' }}
                        attributionControl={false}
                    >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        {route.length > 0 && (
                            <>
                                <Polyline positions={route} color="blue" weight={5} />
                                <FitMapToRoute route={route} />
                            </>
                        )}
                        {/* Verificación adicional de coordenadas antes de mapear */}
                        {Array.isArray(coordinates) && coordinates.length > 0 && coordinates.map((coordinate, index) => (
                            <Marker key={index} position={coordinate} icon={customIcon} />
                        ))}
                        <UpdateMapOnModalOpen />
                    </MapContainer>
                )}
            </div>
        </Modal>
    );
};

export default MapModal;
