import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const handleHome = () => {
      navigate('/principal');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
      <Result
        status="success"
        title="Pago Exitoso"
        subTitle="Gracias por elegirnos. Hemos recibido tu pago."
        extra={[
          <Button type="primary" key="home" onClick={handleHome}>
            Ir al Inicio
          </Button>,
          //<Button key="details">Ver Detalles</Button>,
        ]}
      />
    </div>
  );
};

export default PaymentSuccess;
