import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {
    Table,
    Input,
    Button,
    Form,
    DatePicker,
    Modal,
    Space,
    Tooltip,
    Spin,
    message,
    Row,
    Col,
} from "antd";
import moment from "moment";
import {FormattedMessage} from "react-intl";
// import {SearchOutlined} from "react-icons/io5";
import {
    ForwardOutlined,
    BackwardOutlined,
    ImportOutlined,
    SearchOutlined,
    CloudDownloadOutlined,
    EnvironmentOutlined,
    CheckCircleOutlined,
    SaveOutlined,
    ArrowLeftOutlined,
} from "@ant-design/icons";
import {
    IoCheckmarkCircleSharp,
    IoReload,
    IoLocation,
    IoLocationOutline,
    IoLocationSharp,
} from "react-icons/io5";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import InvoiceDetailsModal from "../facturas/InvoiceDetailsModal";
import GlobalSelect from "../../generales/GlobalSelect";
import {Link} from "react-router-dom";
import InvoicesTable from "./InvoicesTable";
import InvoicesTableAssign from "./InvoicesTableVehicle";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS,
});

function EditVehicleAssignment() {
    const invoicesTableRef = useRef();
    const invoicesTableRefTabla2 = useRef();

    const {t, i18n} = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [form] = Form.useForm();
    const [formBuscarFactura] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal
    const [isModalBuscarFacturaOpen, setIsModalBuscarFacturaOpen] =
        useState(false); // Estado para controlar el modal
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState(t("global.loading"));
    const [bottom, setBottom] = useState("bottomCenter");
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRowKeysX, setSelectedRowKeysX] = useState([]);
    const [isModalOpenDetalles, setIsModalOpenDetalles] = useState(false); // Estado para controlar el modal
    const [selectedInvoice, setSelectedInvoice] = useState(null); // Almacenar la factura seleccionada
    const [vehiculo, setVehiculo] = useState([]);
    const [dataRecord, setDataRecord] = useState([]);
    const [sessionDataStorage, setSessionDataStorage] = useState([]);
    const [datosVehiculo, setDatosVehiculo] = useState([]);

    const headers = {
        accept: "*/*",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
        "Content-Type": "application/json",
    };

    useEffect(() => {
        let dataStorage = JSON.parse(sessionStorage.getItem("sessionStorage"));
        let datos =
            dataStorage.matricula +
            " / " +
            dataStorage.tipo_vehiculo +
            " / " +
            dataStorage.fullname;
        setDatosVehiculo(datos);
        setSessionDataStorage(dataStorage);
    }, []);

    const handleDeselectAll = () => {
        setSelectedRowKeys([]); // Esto desmarca todas las filas
    };
    const [selectedVehiculo, setSelectedVehiculo] = useState(null);

    const asignarPaquetes = () => {
        setSpinTitle(t("global.loading"));
        setSpinning(true);

        Axios.post(
            "sd/asignarPaqueteVehiculo",
            {
                idvehiculo: sessionDataStorage.idvehiculo,
                idinvoice: selectedRowKeys,
            },
            {
                headers: headers,
            }
        )
            .then((response) => {
                setSpinning(false);
                // recargar();
            })
            .catch((error) => {
                setSpinning(false);
            });
    };

    const handleButtonClick = () => {
        asignarPaquetes();
        if (invoicesTableRef.current) {
            invoicesTableRef.current.recargar();
        }
        if (invoicesTableRefTabla2.current) {
            invoicesTableRefTabla2.current.recargar();
        }
    };

    const deletePaquetes = () => {
        setSpinTitle(t("global.loading"));
        setSpinning(true);

        Axios.post(
            "sd/deletePaqueteVehiculo",
            {
                idinvoice: selectedRowKeysX,
                idvehiculo: sessionDataStorage.idvehiculo,
            },
            {
                headers: headers,
            }
        )
            .then((response) => {
                setSpinning(false);
                // recargar();
            })
            .catch((error) => {
                setSpinning(false);
            });
    };
    const handleButtonClickX = () => {
        deletePaquetes();
        if (invoicesTableRef.current) {
            invoicesTableRef.current.recargar();
        }
        if (invoicesTableRefTabla2.current) {
            invoicesTableRefTabla2.current.recargar();
        }
    };

    const handleRowSelectionChange = (keys) => {
        setSelectedRowKeys(keys);
    };
    const handleRowSelectionChangeX = (keys) => {
        setSelectedRowKeysX(keys);
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <p
                style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                }}
            >
                {t("assignment.title")}
                <br></br>
                <br></br>
                {datosVehiculo}
            </p>
            <Space direction="horizontal">
                <Link to="/assignment">
                    <Tooltip title={t("assignment.back")}>
                        <Button
                            style={{marginRight: "0px", marginLeft: "10px"}}
                            type="primary"
                            shape="circle"
                            className="button"
                            icon={<ArrowLeftOutlined/>}
                            disabled={loading}
                        ></Button>
                    </Tooltip>
                </Link>
            </Space>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    gap: "100px", // Espacio entre las tablas y el botón
                    padding: "10px",
                }}
            >
                {/* Primera tabla */}
                <div style={{flex: 1, overflowX: "auto"}}>
                    <p
                        style={{
                            textAlign: "center",
                            margin: "10px 0",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#2c3e50",
                            fontFamily: "'Roboto', sans-serif",
                            borderBottom: "2px solid #61768B",
                            paddingBottom: "5px",
                            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                        }}
                    >
                        {t("assignment.subtitle_assign_pending")}
                    </p>

                    {contextHolder}

                    {error && <div>Error: {error}</div>}
                    <InvoicesTable
                        // data={data}
                        ref={invoicesTableRef}
                        onRowSelectionChange={handleRowSelectionChange}
                        sessionDataStorage={sessionDataStorage}
                        asignada={0}
                        // rowSelection={rowSelection}
                        // showModalVerDetalles={showModalVerDetalles}
                        t={t}
                    />
                </div>

                {/* Botón entre las tablas */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "10px",
                        position: "fixed",
                        top: "50%", // Centra verticalmente en la pantalla
                        zIndex: 1000, // Asegura que el botón quede sobre otros elementos
                        margin: "0 20px", // Margen izquierdo y derecho para separarlo de las tablas
                    }}
                >
                    <Tooltip title={t("invoice.update")}>
                        <Button
                            type="primary"
                            onClick={handleButtonClick}
                            style={{marginBottom: "50px"}}
                            icon={<ForwardOutlined/>}
                        >
                            {/*Acción*/}
                        </Button>
                    </Tooltip>

                    <Tooltip title={t("invoice.update")}>
                        <Button
                            type="primary"
                            onClick={handleButtonClickX}
                            style={{marginBottom: "50px"}}
                            icon={<BackwardOutlined/>}
                        >
                            {/*Acción*/}
                        </Button>
                    </Tooltip>
                </div>

                {/* Segunda tabla */}
                <div style={{flex: 1, overflowX: "auto"}}>
                    <p
                        style={{
                            textAlign: "center",
                            margin: "10px 0",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#2c3e50",
                            fontFamily: "'Roboto', sans-serif",
                            borderBottom: "2px solid #61768B",
                            paddingBottom: "5px",
                            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                        }}
                    >
                        {t("assignment.subtitle_assign")}
                    </p>

                    {contextHolder}

                    {/*<InvoiceDetailsModal*/}
                    {/*    isOpen={isModalOpenDetalles}*/}
                    {/*    onCancel={handleCancelVerDetalles}*/}
                    {/*    selectedInvoice={selectedInvoice}*/}
                    {/*    productColumns={productColumns}*/}
                    {/*/>*/}

                    {error && <div>Error: {error}</div>}
                    <InvoicesTableAssign
                        // data={data}
                        ref={invoicesTableRefTabla2}
                        onRowSelectionChange={handleRowSelectionChangeX}
                        sessionDataStorage={sessionDataStorage}
                        asignada={1}
                        // rowSelection={rowSelection}
                        // showModalVerDetalles={showModalVerDetalles}
                        t={t}
                    />
                </div>
            </div>
        </Spin>
    );
}

export default EditVehicleAssignment;
