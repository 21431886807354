import React, {} from 'react';
import {Navigate, Route, Routes } from "react-router-dom";
import Usuarios from "../modulos/usuarios/Usuarios";
import FormUsuario from '../modulos/usuarios/FormUsuario';
import FormChangeUsserPassword from '../modulos/usuarios/FormChangeUsserPassword';

import Persona from "../modulos/persona/Persona";
import FormAddPersona from '../modulos/persona/FormAddPersona';

import TipoVehiculo from "../modulos/tipovehiculo/TipoVehiculo";
import FormAddTipoVehiculo from '../modulos/tipovehiculo/FormAddTipoVehiculo';

import Vehiculo from "../modulos/vehiculo/Vehiculo";
import FormAddVehiculo from '../modulos/vehiculo/FormAddVehiculo';

import Invoices from "../modulos/invoices/Invoices";
import Tracking from "../modulos/tracking/Tracking";

import Facturas from "../modulos/facturas/Facturas";
import Address from "../modulos/address/Address";
import Mapa from "../modulos/mapa/CubaMap";

import Rutas from "../modulos/rutas/Rutas";

import DeliveryAssignment from "../modulos/deliveryassignment/DeliveryAssignment";
import VehicleAssignment from "../modulos/deliveryassignment/VehicleAssignment";
import EditVehicleAssignment from "../modulos/deliveryassignment/EditVehicleAssignment";

import ConfVariables from "../modulos/confvariables/ConfVariables";

import AuditoriaVehiculo from "../modulos/auditoria/AuditoriaVehiculo";
import AuditoriaPersona from "../modulos/auditoria/AuditoriaPersona";
import AuditoriaRuta from "../modulos/auditoria/AuditoriaRuta";

import PageWelcome from '../generales/PageWelcome';
import FormChangePassword from './FormChangePassword';

import PaymentSuccess from '../modulos/payment/PaymentSuccess';
import PaymentFailed from '../modulos/payment/PaymentFailed';

const RoutersSystem = () => {

    let funcionalidades = (sessionStorage.getItem('funcionalidades'))? JSON.parse(sessionStorage.getItem('funcionalidades')):null;
  
    return (
            <Routes>

                <Route exact path="/principal" element={<PageWelcome />} />
                <Route path="*" element={<Navigate to='/principal' />} />
                
                {/* Todos los usuarios tendrán permiso a cambiar su propia clave  */} 
                <Route path="/changemypass/:id" element={<FormChangePassword />} />

                <Route path="/paymentsuccess" element={<PaymentSuccess />} />
                <Route path="/paymentfailed" element={<PaymentFailed />} />


                {funcionalidades.indexOf("menu_usuarios")!==-1 &&
                       //Gestión de usuarios
                     <>
                         <Route exact path="/usuarios" element={<Usuarios />} />
                         <Route path="/addusuarios" element={<FormUsuario accion = "add"/>} />
                         <Route path="/editusuarios/:id" element={<FormUsuario accion = "mod"/>} />
                         {/*<Route path="/cambiarpass/:id" element={<FormUsuario accion = "pass"/>} />*/}
                         <Route path="/cambiarpass/:id" element={<FormChangeUsserPassword/>} />
                     </>
                }

                {funcionalidades.indexOf("menu_persona")!==-1 &&
                    <>
                        {/*Personas*/}
                        <Route exact path="/persona" element={<Persona />} />
                        <Route path="/addpersona" element={<FormAddPersona accion = "add"/>} />
                        <Route path="/editpersona/:id" element={<FormAddPersona accion = "mod"/>} />

                    </>
                }

                {funcionalidades.indexOf("menu_vehiculos")!==-1 &&
                    <>
                        {/*Tipo de Vehiculo*/}
                        <Route exact path="/tipovehiculo" element={<TipoVehiculo />} />
                        <Route path="/addtipovehiculo" element={<FormAddTipoVehiculo accion = "add"/>} />
                        <Route path="/edittipovehiculo/:id" element={<FormAddTipoVehiculo accion = "mod"/>} />

                        {/*Vehiculo*/}
                        <Route exact path="/vehiculo" element={<Vehiculo />} />
                        <Route path="/addvehiculo" element={<FormAddVehiculo accion = "add"/>} />
                        <Route path="/editvehiculo/:id" element={<FormAddVehiculo accion = "mod"/>} />

                        {/*DeliveryAssignment*/}
                        <Route exact path="/assignment" element={<DeliveryAssignment />} />
                        <Route exact path="/vehicleassignment" element={<VehicleAssignment />} />
                        <Route path="/editvehicleassignment/:id" element={<EditVehicleAssignment accion = "mod"/>} />

                    </>
                }

                {funcionalidades.indexOf("menu_paquetes")!==-1 &&
                    <>
                        {/*Paquetes*/}
                        <Route exact path="/facturas" element={<Facturas />} />
                        <Route exact path="/address" element={<Address />} />

                    </>
                }

                {funcionalidades.indexOf("menu_entregas")!==-1 &&
                    <>
                        {/*Paquetes*/}
                        <Route exact path="/mapa" element={<Mapa />} />
                        <Route exact path="/routes" element={<Rutas />} />

                    </>
                }

                {funcionalidades.indexOf("menu_variables")!==-1 &&
                    <>
                        {/*Paquetes*/}
                        <Route exact path="/variables" element={<ConfVariables />} />

                    </>
                }

                {funcionalidades.indexOf("menu_auditoria")!==-1 &&
                    <>
                        {/*Auditoria*/}
                        <Route exact path="/auditoriavehiculo" element={<AuditoriaVehiculo />} />
                        <Route exact path="/auditoriapersona" element={<AuditoriaPersona />} />
                        <Route exact path="/auditoriaruta" element={<AuditoriaRuta />} />

                    </>
                }

                {funcionalidades.indexOf("menu_mzproducts")!==-1 &&
                    <>
                        {/*Personas*/}
                        <Route exact path="/tracking" element={<Tracking />} />
                        <Route exact path="/invoices" element={<Invoices />} />
                        {/*<Route path="/addpersona" element={<FormAddPersona accion = "add"/>} />*/}
                        {/*<Route path="/editpersona/:id" element={<FormAddPersona accion = "mod"/>} />*/}

                    </>
                }

            </Routes>
    );
};

export default RoutersSystem;