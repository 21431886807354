import React, {useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import {Table, Button, Form} from 'antd';
import _ from 'lodash';
import InvoiceDetailsModal from "../facturas/InvoiceDetailsModal";
import {Axios} from "./EditVehicleAssignment";

const InvoicesTable = forwardRef(({ t, onRowSelectionChange, sessionDataStorage, asignada }, ref) => {
    const filterData = data => formatter => data.map(item => ({
        text: formatter(item),
        value: formatter(item),
    }));
    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [dataRecord, setDataRecord] = useState([]);
    const [bottom, setBottom] = useState('bottomCenter');
    const [isModalOpenDetalles, setIsModalOpenDetalles] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [spinTitle, setSpinTitle] = useState(t('global.loading'));
    const [spinning, setSpinning] = useState(false);
    const handleCancelVerDetalles = () => {
        setIsModalOpenDetalles(false); // Cerrar el modal
        setSelectedInvoice(null); // Limpiar la factura seleccionada
    };

    const showModalVerDetalles = (record) => {
        setSelectedInvoice(record); // Guardar la factura seleccionada
        setIsModalOpenDetalles(true); // Mostrar el modal
    };
    useEffect(() => {
        recargar();
    }, []);

    // Exponer `recargar` usando useImperativeHandle
    useImperativeHandle(ref, () => ({
        recargar,
    }));

    const handleDeselectAll = () => {
        setSelectedRowKeys([]); // Esto desmarca todas las filas
    };

    const recargar = () => {

        setSpinTitle(t('global.loading'));
        setSpinning(true);

        handleDeselectAll();

        // setSelectedRowKeys([]);
        let aux = [];

        // let start = 0;
        // let limit = 1000;

        Axios.post("sd/getFacturas",
            {
                // "start": start,
                // "limit": limit,
                "filtros": [{
                    "asignada": asignada
                }]
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);

            // Asumiendo que response.data.data es un array
            const rawData = response.data.data;

            // No necesitas usar Object.keys, solo asigna rawData directamente
            const formattedData = rawData.map(item => ({
                code: item.code,
                idinvoice: item.idinvoice,
                rdatereserve: item.rdatereserve,
                cname: item.cname,
                cidentity: item.cidentity,
                address: item.address,
                cprovince: item.cprovince,
                cmunicipality: item.cmunicipality,
                sname: item.sname,
                idestadoinvoice: item.idestadoinvoice,
                estadoinvoice: item.estadoinvoice,
                prioridad: item.prioridad,
                latitude: item.latitude,
                longitude: item.longitude,
                coordinates: item.coordinates,
                products: JSON.parse(item.products)  // Asegúrate de que el valor sea un array
            }));

            // console.log(formattedData);
            setData(formattedData);

        }).catch(error => {
            setSpinning(false);
        });
    };
    const columns = [
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_identificador')}</p>,
            dataIndex: 'code',
            key: 'code',
            width: 80,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.code), _.isEqual),
            onFilter: (value, record) => record.code.startsWith(value),
            sorter: (a, b) => a.code.localeCompare(b.code),
            render: (text, record) => (
                <Button type="link" onClick={() => showModalVerDetalles(record)}>
                    {text}
                </Button>
            )
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_fecha_reserva')}</p>,
            dataIndex: 'rdatereserve',
            key: 'rdatereserve',
            width: 150,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.rdatereserve), _.isEqual),
            onFilter: (value, record) => record.rdatereserve.startsWith(value),
            sorter: (a, b) => a.rdatereserve.localeCompare(b.rdatereserve)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_destinatario')}</p>,
            dataIndex: 'cname',
            key: 'cname',
            width: 300,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cname), _.isEqual),
            onFilter: (value, record) => record.cname.startsWith(value),
            sorter: (a, b) => a.cname.localeCompare(b.cname)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_identidad')}</p>,
            dataIndex: 'cidentity',
            key: 'cidentity',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cidentity), _.isEqual),
            onFilter: (value, record) => record.cidentity.startsWith(value),
            sorter: (a, b) => a.cidentity.localeCompare(b.cidentity)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_direccion')}</p>,
            dataIndex: 'address',
            key: 'address',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.address), _.isEqual),
            onFilter: (value, record) => record.address.startsWith(value),
            sorter: (a, b) => a.address.localeCompare(b.address)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_municipio')}</p>,
            dataIndex: 'cmunicipality',
            key: 'cmunicipality',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cmunicipality), _.isEqual),
            onFilter: (value, record) => record.cmunicipality.startsWith(value),
            sorter: (a, b) => a.cmunicipality.localeCompare(b.cmunicipality)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_provincia')}</p>,
            dataIndex: 'cprovince',
            key: 'cprovince',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cprovince), _.isEqual),
            onFilter: (value, record) => record.cprovince.startsWith(value),
            sorter: (a, b) => a.cprovince.localeCompare(b.cprovince)
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_remitente')}</p>,
            dataIndex: 'sname',
            key: 'sname',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.sname), _.isEqual),
            onFilter: (value, record) => record.sname.startsWith(value),
            sorter: (a, b) => a.sname.localeCompare(b.sname)
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_stateinvoice')}</p>,
            dataIndex: 'estadoinvoice',
            key: 'estadoinvoice',
            filterSearch: true,
            width: 150,
            filters: _.uniqWith(filterData(data)(i => i.estadoinvoice), _.isEqual),
            onFilter: (value, record) => record.estadoinvoice.startsWith(value),
            sorter: (a, b) => a.estadoinvoice.localeCompare(b.estadoinvoice)
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('invoice.col_priority')}</p>,
            dataIndex: 'prioridad',
            key: 'prioridad',
            filterSearch: true,
            width: 150,
            filters: _.uniqWith(filterData(data)(i => i.prioridad), _.isEqual),
            onFilter: (value, record) => record.prioridad.startsWith(value),
            sorter: (a, b) => String(a.prioridad).localeCompare(String(b.prioridad))
        }
    ];

    const productColumns = [
        {
            title: t('invoice.col_nombre_producto'),
            dataIndex: 'namegood',
            key: 'namegood',
        },
        {
            title: t('invoice.col_cantidad'),
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: t('invoice.col_peso'),
            dataIndex: 'weight',
            key: 'weight',
        },{
            title: t('invoice.col_longitud'),
            dataIndex: 'length',
            key: 'length',
        },{
            title: t('invoice.col_ancho'),
            dataIndex: 'width',
            key: 'width',
        },{
            title: t('invoice.col_alto'),
            dataIndex: 'height',
            key: 'height',
        },
        {
            title: t('invoice.col_dimensiones'),
            render: (text, record) => `${record.length} x ${record.width} x ${record.height} (cm)`,
            key: 'dimensions',
        },
    ];
    const expandedRowRender = (record) => {
        return <Table columns={productColumns} dataSource={record.products} pagination={false} rowKey="namegood" />;
    };
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        onRowSelectionChange(newSelectedRowKeys);

        if (newSelectedRowKeys.length === 1) {
            const selectedRow = data.find(row => row.code === newSelectedRowKeys[0]);
            // console.log("Selected Row: ", selectedRow);
            if (selectedRow) {
                // setDataRecord(selectedRow.cmunicipality + '. ' + selectedRow.cprovince);
                setDataRecord(selectedRow);
                // console.log(dataRecord);
            }
        }

    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: data.length, // Asegúrate de tener el total de elementos aquí
    });

    const handleTableChange = (newPagination) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            //alignItems: 'center',
            padding: '10px'
        }}>
            <InvoiceDetailsModal
                isOpen={isModalOpenDetalles}
                onCancel={handleCancelVerDetalles}
                selectedInvoice={selectedInvoice}
                productColumns={productColumns}
            />

            <Table
                dataSource={data}
                columns={columns}
                rowKey="idinvoice"
                expandable={{expandedRowRender}}
                rowSelection={rowSelection}
                onChange={handleTableChange}
                pagination={{
                    position: [bottom],
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['20', '40', '60', '80', '100'],
                    showTotal: (total) => `Total ${total} elementos`,
                }} />
        </div>

    );
});

export default InvoicesTable;
