// AdvancedInvoiceSearchModal.js
import React from 'react';
import { Modal, Form, DatePicker, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const AdvancedInvoiceSearchModal = ({ isOpen, onCancel, onFinish, loading, form }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={<div style={{ textAlign: 'center', width: '100%' }}>{t('invoice.advanced_invoice_search')}</div>}
            open={isOpen}
            onCancel={onCancel}
            footer={null}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="rdatereserve"
                    label={t('invoice.form_fecha_reserva')}
                    rules={[{ required: false, message: 'La fecha de reserva es obligatoria' }]}
                >
                    <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} placeholder={t('invoice.form_fecha_reserva')} />
                </Form.Item>

                <Form.Item
                    name="codigo"
                    label={t('invoice.form_identificador')}
                    rules={[{ required: false, message: 'El identificador de factura es obligatorio' }]}
                >
                    <Input placeholder={t('invoice.form_identificador')} />
                </Form.Item>

                <Form.Item
                    name="sname"
                    label={t('invoice.form_remitente')}
                    rules={[{ required: false, message: 'El remitente es obligatorio' }]}
                >
                    <Input placeholder={t('invoice.form_remitente')} />
                </Form.Item>

                <Form.Item
                    name="cname"
                    label={t('invoice.form_destinatario')}
                    rules={[{ required: false, message: 'El destinatario es obligatorio' }]}
                >
                    <Input placeholder={t('invoice.form_destinatario')} />
                </Form.Item>

                <Form.Item>
                    <div style={{ textAlign: 'center' }}>
                        <Button type="default" danger onClick={onCancel} style={{ marginRight: 8 }}>
                            {t('global.cancel')}
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {t('global.acept')}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AdvancedInvoiceSearchModal;
