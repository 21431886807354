// osrmService.js

/**
 * Calcula la distancia entre dos puntos en el espacio geográfico (Haversine formula).
 * @param {Array} point1 - Coordenadas [lat, lng] del primer punto.
 * @param {Array} point2 - Coordenadas [lat, lng] del segundo punto.
 * @returns {number} - Distancia en metros.
 */
function calculateDistance(point1, point2) {
  const R = 6371e3; // Radio de la Tierra en metros
  const [lat1, lng1] = point1.map((deg) => (deg * Math.PI) / 180);
  const [lat2, lng2] = point2.map((deg) => (deg * Math.PI) / 180);
  const deltaLat = lat2 - lat1;
  const deltaLng = lng2 - lng1;

  const a =
    Math.sin(deltaLat / 2) ** 2 +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLng / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

/**
 * Ordena los puntos usando el método del vecino más cercano.
 * @param {Array} coordinatesArray - Array de coordenadas en formato [[lat, lng], [lat, lng], ...].
 * @returns {Array} - Coordenadas reordenadas.
 */
function getNearestNeighborRoute(coordinatesArray) {
  if (coordinatesArray.length <= 1) return coordinatesArray;

  const visited = new Set();
  let currentPoint = coordinatesArray[0];
  const route = [currentPoint];
  visited.add(0);

  while (route.length < coordinatesArray.length) {
    let nearestIndex = -1;
    let nearestDistance = Infinity;

    coordinatesArray.forEach((point, index) => {
      if (!visited.has(index)) {
        const distance = calculateDistance(currentPoint, point);
        if (distance < nearestDistance) {
          nearestDistance = distance;
          nearestIndex = index;
        }
      }
    });

    if (nearestIndex !== -1) {
      currentPoint = coordinatesArray[nearestIndex];
      route.push(currentPoint);
      visited.add(nearestIndex);
    }
  }

  return route;
}

/**
 * Consulta el servicio OSRM para calcular la ruta más corta, distancia y duración.
 * @param {Array} coordinatesArray - Array de coordenadas en formato [[lat, lng], [lat, lng], ...].
 * @returns {Promise<Object>} - Promesa que resuelve con los datos de la ruta (distancia y duración).
 */
export async function getRouteData(coordinatesArray) {
  if (!Array.isArray(coordinatesArray) || coordinatesArray.length === 0) {
    throw new Error("El array de coordenadas no es válido.");
  }

  // Reordenar las coordenadas usando el método del vecino más cercano
  const optimizedCoordinates = getNearestNeighborRoute(coordinatesArray);

  // Convertir al formato lng,lat;lng,lat
  const coordinates = optimizedCoordinates
    .map(([lat, lng]) => `${lng},${lat}`)
    .join(";");

  const url = `http://router.project-osrm.org/route/v1/driving/${coordinates}?overview=full&geometries=geojson`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(
        `Error en la respuesta del servicio OSRM: ${response.statusText}`
      );
    }

    const data = await response.json();
    if (!data.routes || data.routes.length === 0) {
      throw new Error("No se encontraron rutas en la respuesta.");
    }

    const route = data.routes[0];
    return {
      distance: route.distance, // Distancia total en metros
      duration: route.duration, // Tiempo estimado en segundos
      geometry: route.geometry, // Ruta en formato GeoJSON
      optimizedCoordinates, // Coordenadas optimizadas
    };
  } catch (error) {
    console.error("Error al consultar el servicio OSRM:", error);
    throw error;
  }
}
